import React from 'react';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import {
  BaseAlignmentIcon,
  EditorMenuButton,
  EditorMenuDropdown,
  EditorMenuDropdownButton,
} from '../editorUtilityFunctions/styledContents';
import { ReactComponent as JustifyAlignmentButton } from '../editorFormattingIcons/justifyAlignment.svg';
import { ReactComponent as LeftAlignmentButton } from '../editorFormattingIcons/leftAlignment.svg';
import { ReactComponent as MainAlignmentButton } from '../editorFormattingIcons/alignmentButton.svg';
import { ReactComponent as CenterAlignment } from '../editorFormattingIcons/centerAlignment.svg';
import { ReactComponent as RightAlignmentButton } from '../editorFormattingIcons/rightAlignment.svg';
import { AlignmentButtonProps } from '../editorUtilityFunctions/lintEditorType';
import { IconDiv } from '../editorUtilityFunctions/utilityFunctions';

export function AlignmentButton(props: AlignmentButtonProps) {
  const {
    activeLeft,
    activeCenter,
    activeRight,
    activeJustify,
    onLeft,
    onCenter,
    onRight,
    onJustify,
  } = props;

  return (
    <EditorMenuDropdown
      placement='bottom'
      element={
        <EditorMenuButton data-testid='alignment-btn'>
          <Tooltip info='Alignment'>
            <IconDiv>
              <MainAlignmentButton />
            </IconDiv>
          </Tooltip>
        </EditorMenuButton>
      }
    >
      <Tooltip info='Left'>
        <EditorMenuDropdownButton onClick={onLeft} data-testid='alignment-left-btn'>
          <BaseAlignmentIcon as={LeftAlignmentButton} className={activeLeft ? 'is-active' : ''} />
        </EditorMenuDropdownButton>
      </Tooltip>
      <Tooltip info='Center'>
        <EditorMenuDropdownButton onClick={onCenter} data-testid='alignment-center-btn'>
          <BaseAlignmentIcon as={CenterAlignment} className={activeCenter ? 'is-active' : ''} />
        </EditorMenuDropdownButton>
      </Tooltip>
      <Tooltip info='Right'>
        <EditorMenuDropdownButton onClick={onRight} data-testid='alignment-right-btn'>
          <BaseAlignmentIcon as={RightAlignmentButton} className={activeRight ? 'is-active' : ''} />
        </EditorMenuDropdownButton>
      </Tooltip>
      <Tooltip info='Justify'>
        <EditorMenuDropdownButton onClick={onJustify} data-testid='alignment-justify-btn'>
          <BaseAlignmentIcon
            as={JustifyAlignmentButton}
            className={activeJustify ? 'is-active' : ''}
          />
        </EditorMenuDropdownButton>
      </Tooltip>
    </EditorMenuDropdown>
  );
}
