import React, { ReactNode } from 'react';
import './tooltip.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TooltipInterface {
  children: ReactNode;
  info: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
}

export const InteractiveTooltip = (props: TooltipInterface) => {
  const { children, info, direction = 'auto' } = props;

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={direction}
      overlay={<Tooltip className='interactiveTooltipInfo'>{info}</Tooltip>}
    >
      <span className='interactivetooltiptarget' onMouseEnter={() => {}} onMouseLeave={() => {}}>
        {children}
      </span>
    </OverlayTrigger>
  );
};
