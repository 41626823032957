import React from 'react';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { LintEditorFormatPropsType } from './lintEditorType';
import { EditorFormatButton } from './styledContents';
import lintStyle from '../editorUtilityFunctions/loreeInteractiveEditor.module.scss';
import { ReactComponent as BoldButton } from '../editorFormattingIcons/boldButton.svg';
import { ReactComponent as ItalicButton } from '../editorFormattingIcons/italicButton.svg';
import { ReactComponent as UnderlineButton } from '../editorFormattingIcons/underlineButton.svg';
import { IconDiv } from './utilityFunctions';

export function TextFormatButton(props: LintEditorFormatPropsType) {
  const { editor } = props;
  if (!editor) {
    return null;
  }
  return (
    <>
      <EditorFormatButton
        className={
          editor.isActive('bold')
            ? `${lintStyle.formatButton} is-active`
            : `${lintStyle.textFormatButton}`
        }
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <Tooltip info='Bold'>
          <IconDiv>
            <BoldButton className='textFormatButton' />
          </IconDiv>
        </Tooltip>
      </EditorFormatButton>
      <EditorFormatButton
        className={
          editor.isActive('italic')
            ? `${lintStyle.formatButton} is-active`
            : `${lintStyle.formatButton}`
        }
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
        }}
      >
        <Tooltip info='Italic'>
          <IconDiv>
            <ItalicButton className='textFormatButton' />
          </IconDiv>
        </Tooltip>
      </EditorFormatButton>
      <EditorFormatButton
        className={
          editor.isActive('underline')
            ? `${lintStyle.formatButton} is-active`
            : `${lintStyle.formatButton}`
        }
        onClick={() => {
          editor.commands.toggleUnderline();
        }}
      >
        <Tooltip info='Underline'>
          <IconDiv>
            <UnderlineButton className='textFormatButton' />
          </IconDiv>
        </Tooltip>
      </EditorFormatButton>
    </>
  );
}
