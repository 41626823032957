import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Componentlist from '../components/library';
import HealthCheck from '../healthCheck';
import NotFoundPage from '../404';
import AccessDeniedPage from '../401';
import S3Upload from '../middleware/s3Upload';
import UserInteractives from '../components/interactiveManagement/userInteractives';
import SharedInteractives from '../components/interactiveManagement/sharedInteractives';
import GlobalInteractives from '../components/interactiveManagement/globalInteractives';
import Migration from '../middleware/migration';
import FormComponent from '../components/formComponent';
import NoContentComponent from '../204';
import InteractiveView from '../components/Interactives/index';
import IconsSidebar from '../components/layouts/sidebar';

const Routing = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={[
            '/interactives/:user/:id/:version',
            '/contents/:user/:id',
            '/shared',
            '/global/:orgId',
          ]}
          render={() => (
            <>
              <div className='layout-container'>
                <div className='icon-sidebar'>
                  <IconsSidebar />
                </div>
                <div className='icon-content'>
                  <Switch>
                    <Route
                      exact
                      path='/interactives/:user/:id/:version'
                      component={Componentlist}
                    />
                    <Route path='/contents/:user/:id' component={UserInteractives} />
                    <Route path='/shared' component={SharedInteractives} />
                    <Route path='/global/:orgId' component={GlobalInteractives} />
                  </Switch>
                </div>
              </div>
            </>
          )}
        />
        {/* Other routes without sidebar */}
        <Route exact path='/component/:type' component={FormComponent} />
        <Route
          path='/component/duplicate/:type/:user/:id/:orgId/:version'
          component={FormComponent}
        />
        <Route path='/component/edit/:type/:user/:id/:orgId/:version' component={FormComponent} />
        <Route path='/element/edit/:type/:id/:orgId/:version' component={FormComponent} />
        <Route exact path='/contentview/:type/:user/:id' component={InteractiveView} />
        <Route exact path='/:type/:id' component={InteractiveView} />
        <Route path='/s3upload/:id' component={S3Upload} />
        <Route path='/migrate/:orgId' component={Migration} />
        <Route path='/healthcheck' component={HealthCheck} />
        <Route path='/401' component={AccessDeniedPage} />
        <Route path='/204' component={NoContentComponent} />
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
