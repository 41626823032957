import React from 'react';
import './loader.scss';

interface InteractiveLoaderProps {
  loaderType: string;
}

const InteractiveLoader = (loaderType?: InteractiveLoaderProps) => {
  return (
    <>
      <div className='interactive-loader'>
        <div className='margin container-fluid pt-3'>
          <div className='d-flex justify-content-between mb-3 row'>
            <div className='col-10 col'>
              <div className='text-center skeleton-title-short'></div>
            </div>
            <div className='col-2 col'>
              <div className='d-flex justify-content-end' style={{ gap: '16px' }}>
                <div className='text-center skeleton-title-small'></div>
                <div className='text-center skeleton-title-small'></div>
              </div>
            </div>
          </div>
          <div className='bodyLoader'></div>
        </div>
      </div>
    </>
  );
};
export default InteractiveLoader;
