import React from 'react';
import { Level } from '@tiptap/extension-heading';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { HeaderContentType } from './lintEditorType';
import { EditorMenuButton, HeaderParagraphMenu, HeadingParagraphButton } from './styledContents';
import { ReactComponent as HeadingButton } from '../editorFormattingIcons/headingButton.svg';
import { parseFontSize, setDefaultFontStyle } from '../editorExtensions/utilityFunctions';
import { IconDiv } from './utilityFunctions';

export function HeadingFormatButton(props: HeaderContentType) {
  const { editor, editorConfig } = props;

  if (!editor) {
    return null;
  }

  const setHeading = (level: Level | 0) => {
    let adminDashboardStyles;
    if (editorConfig?.customHeaderStyleList.length) {
      adminDashboardStyles = setDefaultFontStyle(editorConfig?.customHeaderStyleList, level);
    }
    if (level === 0) {
      if (adminDashboardStyles) {
        const customFontSize = parseFontSize(adminDashboardStyles.size);

        const styles = `font-family: ${adminDashboardStyles.font}; font-size: ${
          customFontSize?.size ?? '16'
        }${customFontSize?.unit ?? 'px'};`;
        editor.chain().setCustomParagraph({ style: styles, attrs: null }).run();
      } else {
        editor.chain().setCustomParagraph({ style: null, attrs: null }).run();
      }
    } else {
      if (adminDashboardStyles) {
        const customFontSize = parseFontSize(adminDashboardStyles.size);
        if (customFontSize) {
          const styles = `font-family: ${adminDashboardStyles.font}; font-size: ${
            customFontSize?.size ?? '16'
          }${customFontSize?.unit ?? 'px'};`;
          editor
            .chain()
            .unsetFontFamily()
            .unsetFontSize()
            .setCustomHeading({ level: level, style: styles })
            .run();
        }
      } else {
        editor.commands.setCustomHeading({ level: level });
      }
    }
  };
  const getHeading = (level: Level | 0) => {
    if (level === 0 && editor.isActive('paragraph')) {
      return 'is-active';
    } else if (editor.isActive('heading', { level: level })) {
      return 'is-active';
    }
    return '';
  };
  return (
    <HeaderParagraphMenu
      placement='bottom-start'
      boundary={document.body}
      showCenter
      element={
        <EditorMenuButton data-testid='heading-btn'>
          <Tooltip direction='bottom' info='Heading'>
            <IconDiv>
              <HeadingButton />
            </IconDiv>
          </Tooltip>
        </EditorMenuButton>
      }
    >
      <HeadingParagraphButton
        className={getHeading(1)}
        onClick={() => setHeading(1)}
        data-testid='headingButton1'
      >
        <h1>Heading 1</h1>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(2)}
        onClick={() => setHeading(2)}
        data-testid='headingButton2'
      >
        <h2>Heading 2</h2>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(3)}
        onClick={() => setHeading(3)}
        data-testid='headingButton3'
      >
        <h3>Heading 3</h3>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(4)}
        onClick={() => setHeading(4)}
        data-testid='headingButton4'
      >
        <h4>Heading 4</h4>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(5)}
        onClick={() => setHeading(5)}
        data-testid='headingButton5'
      >
        <h5>Heading 5</h5>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(6)}
        onClick={() => setHeading(6)}
        data-testid='headingButton6'
      >
        <h6>Heading 6</h6>
      </HeadingParagraphButton>
      <HeadingParagraphButton
        className={getHeading(0)}
        onClick={() => setHeading(0)}
        data-testid='paragraphButton'
      >
        <p>Paragraph text</p>
      </HeadingParagraphButton>
    </HeaderParagraphMenu>
  );
}
