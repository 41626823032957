import styled from 'styled-components';
import {
  Dropdown,
  FontSizeSelector,
  FontSelector,
  FontUnitSelector,
  Select,
  LineHeightOption,
} from '@crystaldelta/loree-ui-components';
import { DropdownItem } from 'react-bootstrap';

export const EditorTopSection = styled.div`
  border-radius: 4px;
  border: 1px solid #cccccc;
  background: #fff;
  margin: 10px 0px;
`;

export const LoreeInteractiveEditorWrapper = styled.div`
  position: relative;
`;

export const FormatSection = styled.div`
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`;

export const EditorFormatButton = styled('button')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: none;
  flex-direction: column;
  padding: 0px;
`;

export const EditorMenuDropdown = styled(Dropdown)`
  border-radius: 4px;
  display: inline-flex;
  border: 1px solid #112299;
  padding: 0 0px;
  background-color: white;
`;

export const EditorMenuButton = styled.button`
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  color: #000;
  margin: 0px;
  border-width: 0px;
  background-color: #ffffff;
  display: flex;
  padding: 0px;
  border-radius: 2px;
  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:focus {
    outline: 0px;
  }
  &.is-active svg path {
    fill: #112299 !important;
    color: #112299 !important;
  }
  &.is-active .IconContainer {
    background-color: #e7e9f5;
  }
  &.is-arrow {
    border: 0;
    outline: none !important;
    width: 17px !important;
    height: 30px;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const EditorMenuDropdownButton = styled.button`
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 9px;
  background: transparent;
  border: 0;
  width: 35px;
  height: 35px;

  &.is-active svg {
    fill: #112299 !important;
    color: #112299 !important;
  }
  &:focus {
    outline: 2px solid #112299;
    border-radius: 2px;
  }
`;

export const HeaderParagraphMenu = styled(Dropdown)`
  background-color: white;
  border-radius: 2px;
  border: solid 1px black;
  padding: 0px;
  z-index: 10;
`;

export const HeadingParagraphButton = styled.button`
  display: block;
  flex: 1;
  background: transparent;
  border: 0;
  text-align: left;
  width: 100%;
  padding: 0px;
  cursor: pointer;
  &.is-active {
    background: #e2e0f2;
    color: #000000;
    width: 100%;
  }
  :hover {
    background: #112299;
    color: #ffffff;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    cursor: pointer !important;
    margin: 0px;
    width: 100%;
    padding: 5px 10px;
  }
`;

export const TableMenuButton = styled.button`
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  color: #000;
  margin: 0px;
  border-width: 0px;
  background-color: #ffffff;
  display: flex;
  border-radius: 2px;
  > div {
    padding: 3px 0px;
  }
  div {
    display: flex;
  }
  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:focus {
    outline: 0px;
  }
  &.is-active svg path {
    fill: #112299 !important;
    color: #112299 !important;
  }
  &.is-arrow {
    border: 0;
    outline: none !important;
    width: 17px !important;
    height: 30px;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const TableMenuSection = styled.div`
  display: flex;
  align-items: center;
  button {
    border: 1px solid #e9e9e9;
    gap: 8;
    border-radius: 6px;
    height: 34px;
  }
  button[data-testid='table-icon'][aria-expanded='true'] {
    border: 1px solid #b5badf !important;
  }
`;

export const TableButtonMenu = styled(Dropdown)`
  background-color: white;
  border-radius: 2px;
  border: solid 1px black;
  z-index: 10;
`;

export const TableMenuDropdown = styled(Dropdown)`
  background-color: white;
  z-index: 10;
  width: 167px;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #112299;
  padding: 3px;
`;

export const TableMenuOptionButton = styled(DropdownItem)`
  display: flex;
  flex: 1;
  background: transparent;
  border: 0;
  width: 159px;
  height: 29px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  padding: 5px;
  &:hover,
  &.activeTableSubMenu {
    background: #f2f2f2 !important;
    color: #1c1c1c !important;
    path {
      fill: #1c1c1c;
    }
  }
  &:hover:not([disabled]) {
    background: ${(props) => (props.disabled ? 'transparent' : '#e2e0f2')};
    color: ${(props) => (props.disabled ? '#999' : '#112299')};
    fill: ${(props) => (props.disabled ? '#999' : '#ffffff')};
    stroke-opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }
  &:focus {
    outline: none;
  }
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: #000;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &[disabled] {
    svg {
      fill: #b7b7b7;
    }
  }
  div {
    width: 100%;
    display: flex;
  }
`;

export const BorderTableMenuSection = styled.div`
  position: relative;
`;

export const BorderTableMenuDropdown = styled(Dropdown)`
  background-color: white;
  z-index: 10;
  width: ${(props) => props.width};
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #112299;
  padding: 3px;
`;

export const BorderTableMenuButton = styled.button`
  align-items: center;
  justify-content: left;
  outline: none;
  box-shadow: none;
  color: #000;
  margin: 0px;
  border-width: 0px;
  background-color: #ffffff;
  display: flex;
  padding: 3px 5px;
  border-radius: 4px;
  border: 1px solid #8f8f8f !important;
  div {
    display: flex;
  }
  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:focus {
    outline: 0px;
    border: 1px solid #112299 !important;
  }
  &.is-active svg path {
    fill: #112299 !important;
    color: #112299 !important;
  }
  &.is-arrow {
    border: 0;
    outline: none !important;
    width: 17px !important;
    height: 30px;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const BorderTableMenuOptionButton = styled(DropdownItem)`
  display: flex;
  flex: 1;
  background: transparent;
  border: 0;
  width: 100%;
  height: 29px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  padding: 5px;
  &:hover:not([disabled]) {
    background: ${(props) => (props.disabled ? 'transparent' : '#e2e0f2')};
    color: ${(props) => (props.disabled ? '#999' : '#112299')};
    fill: ${(props) => (props.disabled ? '#999' : '#ffffff')};
    stroke-opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }
  &:focus {
    outline: none;
  }
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: #000;
  }
  &[disabled] {
    svg {
      fill: #b7b7b7;
    }
  }
  &.active {
    background: #e2e0f2;
    color: #112299;
    fill: #ffffff;
    stroke-opacity: 1;
  }
  div {
    width: 100%;
    display: flex;
  }
`;

export const EditorFontSelector = styled(FontSelector)`
  width: 170px;
  padding: 6px 12px;
  border-radius: 2px;
  border: 1px solid #112299;
  background: #fff;
`;

export const FontFamilyButtonStyle = styled(Select)`
  background-color: white;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  gap: 8px;
  color: #585858;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 350;
  outline: none !important;
  width: 168px;
  height: 34px;
  border-radius: 8px;
  &:focus {
    outline: unset !importtant;
  }
  &[aria-expanded='true'] {
    border: 1px solid #ababab !important;
  }
`;

export const FontFamilyOption = styled(LineHeightOption)`
  padding-left: 10px;
  padding-top: 4px;
  font-family: ${(props) => (props.value ? props.value : 'SourceSansPro-Regular')};
  span {
    width: 100%;
  }
  &:hover {
    background: #112299;
    color: #ffffff;
  }
`;

export const EditorFontSizeSelector = styled(FontSizeSelector)`
  width: 68px;
  height: 34px;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background: #fff;
  gap: 8;
  color: #585858;
  &[aria-expanded='true'] {
    border: 1px solid #ababab !important;
  }
`;

export const EditorFontUnitSelector = styled(FontUnitSelector)`
  width: 68px;
  height: 34px;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #585858;
  &[aria-expanded='true'] {
    border: 1px solid #ababab !important;
  }
`;

export const FontOptionList = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FontStyleList = styled.span`
  display: inline-block;
  max-width: 40%;
`;

export const BaseAlignmentIcon = styled.div`
  &.is-active {
    fill: #112299;
    color: #112299;
  }

  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const BackGroundButton = styled(EditorMenuButton)`
  background-color: #fff;
`;

export const LineHeightButtonStyle = styled(Select)`
  background-color: white;
  padding: 4px 10px;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  gap: 8px;
  color: #585858;
  font-family: Lexend;
  font-size: 14px;
  outline: none !important;
  height: 34px;
  border-radius: 8px;
  color: #585858;
  svg {
    margin-right: 8px;
    margin-left: 2px;
  }
  &:focus {
    outline: unset !importtant;
  }
  &[aria-expanded='true'] {
    border: 1px solid #ababab !important;
  }
`;
export const StyledLineHeightOption = styled(LineHeightOption)`
  padding-top: 4px;
  &:hover {
    background: #112299;
    color: #ffffff;
  }
`;

export const TableRowsAndColumnSelect = styled(Select)`
  background-color: white !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  border: 1px solid #e9e9e9;
  gap: 8px;
  color: #585858;
  font-family: Lexend;
  font-size: 14px;
  outline: none !important;
  width: 70px;
  height: 34px;
  border-radius: 8px;
  &:focus {
    outline: unset !importtant;
  }
`;

export const TableRowsAndColumnOption = styled(LineHeightOption)`
  padding-left: 10px;
  padding-top: 4px;
  font-family: ${(props) => (props.value ? props.value : 'SourceSansPro-Regular')};
  span {
    width: 100%;
  }
  &:hover {
    background: #112299;
    color: #ffffff;
  }
`;

export const AltTextInput = styled.input`
  border: 1px solid #909090;
  font-size: 14px;
  outline: none;
  accent-color: #112299;
`;
