/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import ModalComponent from '../utils/modal';
import { Form } from 'react-bootstrap';
import { Editor } from '@tiptap/react';
import {
  getAltText,
  hasDecorationText,
  modifyAltText,
} from '../editor/editorExtensions/utilityFunctions';
import { AltTextInput } from '../editor/editorUtilityFunctions/styledContents';
import { hideLinkInfoPopup } from './showLinkInfoPopup';
import { fontWeight } from '../../redux/form/form.actions';

interface ImageOptionsProps {
  editor: Editor;
}

const ImageOptions = (props: Readonly<ImageOptionsProps>) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmButtonState, setConfirmButtonState] = useState(false);

  const [altText, setAltText] = useState('');

  useEffect(() => {
    const { editor } = props;
    const alterationText = getAltText(props.editor);
    if (hasDecorationText(editor)) {
      setDecorativeStatus(true);
    } else {
      setAltText(alterationText ?? '');
      setDecorativeStatus(false);
    }
  }, [props]);

  const [decorativeStatus, setDecorativeStatus] = useState(true);

  const handleAltTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim() === '') {
      setConfirmButtonState(true);
    } else {
      setConfirmButtonState(false);
    }
    setAltText(value);
  };

  const handleDecorativeButtonEvent = () => {
    if (decorativeStatus && altText === '') {
      setConfirmButtonState(true);
    } else {
      setConfirmButtonState(false);
    }
    setDecorativeStatus(!decorativeStatus);
  };

  const handleSave = () => {
    modifyAltText(altText, decorativeStatus, props.editor);
    handleClose();
  };

  const handleClose = () => {
    setShowModal(false);
    hideLinkInfoPopup();
  };
  return (
    <>
      <span
        role='button'
        onClick={() => {
          setShowModal(true);
        }}
        className='p-1'
        tabIndex={0}
      >
        Image Options
      </span>
      <ModalComponent
        show={showModal}
        handleCancel={handleClose}
        modalDetails={{
          title: 'Image Options',
          body: (
            <>
              <div className='d-flex flex-column'>
                <label className='pb-1 mb-0' htmlFor='lint-image-alt-input'>
                  Alternative text
                </label>
                <AltTextInput
                  name='lint-image-alt-input'
                  className='p-1 lint-image-modal-text-area'
                  type='text'
                  autoComplete='off'
                  placeholder='Alternative text'
                  maxLength={150}
                  value={altText.replace(/ +/g, ' ')}
                  disabled={decorativeStatus}
                  onChange={(event) => {
                    handleAltTextChange(event);
                  }}
                />
              </div>
              <div className='d-flex pt-2'>
                <Form.Label htmlFor='decorativeImageLabel' className=' links-tab-label'>
                  <AltTextInput
                    onClick={(e) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      e.stopPropagation();
                      handleDecorativeButtonEvent();
                    }}
                    type='checkbox'
                    id='decorativeImageLabel'
                    name='decorativeImageLabel'
                    checked={decorativeStatus}
                    onChange={() => {}}
                  />
                  <span className='px-2'>Decorative Image</span>
                </Form.Label>
              </div>
            </>
          ),
        }}
        confirmButton={{
          label: 'Save',
          disabled: confirmButtonState,
          handleConfirm: handleSave,
        }}
        cancelButton={{
          label: 'Cancel',
        }}
      />
    </>
  );
};

export default ImageOptions;
