import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as GlobalIcon } from '../../../src/assets/icons/globalIcon.svg';
import { ReactComponent as PrivateIcon } from '../../../src/assets/icons/sharedIcon.svg';
import { ReactComponent as SharedIcon } from '../../../src/assets/icons/privateIcon.svg';
import { ReactComponent as CreateIcon } from '../../../src/assets/icons/createIcon.svg';
import { InteractiveTooltip } from '../utils/interactivetooltip';
interface IconData {
  className: string;
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pathname: string;
  tooltipInfo: string;
}

const icons: IconData[] = [
  {
    className: 'createIcon',
    IconComponent: CreateIcon,
    pathname: `/interactives/${sessionStorage.getItem('user')}/${sessionStorage.getItem(
      'org_id',
    )}/${sessionStorage.getItem('loreeVersion')}`,
    tooltipInfo: 'Create New',
  },
  {
    className: 'globalIcon',
    IconComponent: GlobalIcon,
    pathname: `/global/${sessionStorage.getItem('org_id')}`,
    tooltipInfo: 'Global Interactive',
  },
  {
    className: 'privateIcon',
    IconComponent: PrivateIcon,
    pathname: `/Contents/${sessionStorage.getItem('user')}/${sessionStorage.getItem('user_id')}`,
    tooltipInfo: 'Private Interactive',
  },
  {
    className: 'sharedIcon',
    IconComponent: SharedIcon,
    pathname: '/shared',
    tooltipInfo: 'Shared Interactive ',
  },
];

const IconsSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const renderIcon = ({ className, IconComponent, pathname, tooltipInfo }: IconData) => {
    const isActive = location.pathname === pathname;
    return (
      <InteractiveTooltip info={tooltipInfo} direction='right' key={className}>
        <div
          key={className}
          className={`${className}Div ${isActive ? 'active' : ''}`}
          role='button'
          tabIndex={0}
          onClick={() => history.push(pathname)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              history.push(pathname);
            }
          }}
        >
          <div className={className}>
            <IconComponent width={20} height={20} />
          </div>
        </div>
      </InteractiveTooltip>
    );
  };

  const filteredIcons = location.pathname.startsWith('/interactives/')
    ? icons.filter((icon) => icon.className !== 'createIcon')
    : icons;
  return <div className='left-content'>{filteredIcons.map((icon) => renderIcon(icon))}</div>;
};

export default IconsSidebar;
