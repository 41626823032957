import React from 'react';
import './loader.scss';
import { Container, Row, Table } from 'react-bootstrap';
interface CircleLoaderProps {
  loaderType: string;
}

const CircleLoader = (loaderType?: CircleLoaderProps) => {
  const loaderCount = 8;
  const table = ['one', 'two'];
  return (
    <>
      {loaderType?.loaderType !== 'tableViewLoader' ? (
        <div className='loader-container'>
          <Container fluid className='margin-loader pt-4 landingPageLoreeInteractive-loader'>
            <Row xs={1} md={4} sm={2} lg={4} className='g-4'>
              {Array.from({ length: loaderCount }).map((_, index) => (
                <div key={index} className='col'>
                  <div id='interactiveCards' className='rounded-lg h-100 card card-style'>
                    <div className='card-img img-fluid p-3'></div>
                    <div className='p-0 card-body title-body mx-3'>
                      <div className='text-center skeleton-title-short'></div>
                      <div className='text-center skeleton-title-medium'></div>
                      <div className='text-center skeleton-title-small'></div>
                      <div className='text-center skeleton-title-large'></div>
                    </div>
                    <div
                      className='bg-white border-0 text-center card-footer'
                      id='interactiveFooter'
                    >
                      <div
                        id='interactiveCreateBtn'
                        className='skeleton-title-button-medium btn btn-sm'
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </Container>
        </div>
      ) : (
        <div className='table-page-loader'>
          <Table
            striped
            responsive
            borderless
            size='md'
            className='table-page lint-custom-tanStack-table'
          >
            <thead className='table-page-head'>
              <tr className='tablepage-head-row'>
                <th style={{ width: '20px' }}>
                  <div className='text-center skeleton-title-short'></div>
                </th>
                <th style={{ width: '60%' }}>
                  <div className='text-center skeleton-title-large'></div>
                </th>
                <th style={{ width: '20p%' }}>
                  <div className='text-center skeleton-title-short'></div>
                </th>
                <th style={{ width: '10%' }}>
                  <div className='text-center skeleton-title-small'></div>
                </th>
                <th style={{ width: '10%' }}>
                  <div className='text-center skeleton-title-small'></div>
                </th>
                <th style={{ width: '5%' }}>
                  <div className='text-center skeleton-title-short'></div>
                </th>
              </tr>
            </thead>
            <tbody className='table-page-head'>
              {Array.from({ length: loaderCount }).map((_, index) => {
                const classes = ['large', 'medium', 'short', 'small'];
                const alternatingClass = `skeleton-title-body-${classes[index % classes.length]}`;

                return (
                  <tr className='tableRow' key={index}>
                    <th className='table-body-cell-loader'>
                      <div className='text-center skeleton-title-short'></div>
                    </th>
                    <th className='table-body-cell-loader'>
                      <div className={`text-center ${alternatingClass}`}></div>
                    </th>
                    <th className='table-body-cell-loader'>
                      <div className='text-center skeleton-title-short'></div>
                    </th>
                    <th className='table-body-cell-loader'>
                      <div className='text-center skeleton-title-small'></div>
                    </th>
                    <th className='table-body-cell-loader'>
                      <div className='text-center skeleton-title-button'></div>
                    </th>
                    <th className='table-body-cell-loader'>
                      <div className='text-center skeleton-title-short'></div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
export default CircleLoader;
