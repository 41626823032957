import React, { ReactNode } from 'react';
import { ReactComponent as ExitIcon } from '../assets/icons/exitIcon.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/loree-deleteIcon.svg';


export const setSharedAccountId = (id: string) => {
  if (!id || id === '') return;
  const sharedId = id.split('?');
  sessionStorage.setItem('sharedAccount', sharedId[1]);
};

const getInteractiveType = (privateState: boolean, globalState: boolean) => {
  switch (true) {
    case !privateState && globalState:
      return 'global';
    case !privateState && !globalState:
      return 'shared';
    default:
      return 'private';
  }
};

export const validateExternalLink = (url: string) => {
  try {
    const isValidUrl = new URL(url);
    return isValidUrl ? url : { error: 'null' };
  } catch (error) {
    return { error };
  }
};

export const getEditStatus = (
  privateState: boolean,
  globalState: boolean,
  config: {
    myinteractiveedit: boolean;
    globalinteractiveedit: boolean;
    sharedinteractiveedit: boolean;
  },
) => {
  const interactiveType = getInteractiveType(privateState, globalState);
  switch (interactiveType) {
    case 'private':
      return config.myinteractiveedit;
    case 'global':
      return config.globalinteractiveedit;
    case 'shared':
      return config.sharedinteractiveedit;
    default:
      return false;
  }
};

export const getModalDetails = (type: string, title?: string | JSX.Element) => {
  switch (type) {
    case 'Exit':
      return {
        title: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ExitIcon />
            <span
              style={{ color: ' #585858', fontWeight: 400, fontSize: '16px', fontFamily: 'Lexend' }}
            >
              Exit Interactive
            </span>
          </div>
        ),
        body: (
          <div
            style={{
              color: '#585858',
              fontWeight: 350,
              fontSize: '14px',
              fontFamily: 'Lexend',
              paddingBottom: '16px',
            }}
            className='p-3'
          >
            Are you sure want to exit? Changes you made may not be saved.
          </div>
        ) as ReactNode,
      };
    case 'Delete':
      return {
        title: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <DeleteIcon />
            <span
              style={{ color: ' #585858', fontWeight: 400, fontSize: '16px', fontFamily: 'Lexend' }}
            >
              Delete Interactive
            </span>
          </div>
        ),
        body: (
          <div
            style={{
              color: '#585858',
              fontWeight: 300,
              fontSize: '14px',
              fontFamily: 'Lexend',
              padding: '16px',
            }}
          >
            Are you sure you want to delete <b>{`"${title}"`}</b> permanently?
            <br />
            <br /> This action cannot be undone and the component will be deleted wherever it is
            embedded
          </div>
        ) as ReactNode,
      };
    case 'Remove':
      return {
        title: 'Remove Section',
        body: (
          <div>
            Are you want to remove <b>{`${title}`}</b>?
          </div>
        ) as ReactNode,
      };
    case 'Remove Option':
      return {
        title: 'Remove Option',
        body: (
          <div>
            Are you want to remove <b>{`${title}`}</b>?
          </div>
        ) as ReactNode,
      };
    default:
      return {
        title: '',
        body: (<></>) as ReactNode,
      };
  }
};

export const getValueFromSession = (type: string) => {
  return sessionStorage.getItem(`${type}`);
};

export const shuffleArrayValues = (arr: Array<string>) => {
  for (let i = arr.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};
