/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Form } from 'react-bootstrap';
import OptionSection from './optionSection';
import { LoreeInteractiveEditor } from '../../editor/loreeInteractiveEditor';
import { CONSTANTS } from '../../editor/editorUtilityFunctions/constants';
import { LoreeInteractiveEditorDashboardContentType } from '../../editor/editorUtilityFunctions/lintEditorType';

interface MCQFormBodyComponentProps {
  panel: Array<string>;
  index: number;
  errors: any;
  parameter: any;
  handleChange: Function;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
  indexValue: Array<string>;
}

const MCQFormBodyComponent = (props: MCQFormBodyComponentProps) => {
  const { panel, index, errors, parameter, handleChange, editorConfig } = props;

  const handleEditor = (index: number, value: string) => {
    let fail = errors;
    const content = parameter;
    content[index] = { ...content[index], question: value };
    handleChange({
      parameters: content,
      errors: fail,
    });
  };

  return (
    <div>
      <Form.Group className='section-content sectionBody'>
        <Form.Label className='title option_section' htmlFor={panel[0]}>
          Question *
        </Form.Label>
        <LoreeInteractiveEditor
          initialValue={parameter[index]['question'] ?? CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT}
          editorConfig={editorConfig}
          updateEditorContent={(value: string) => handleEditor(index, value)}
          indexValue={props.indexValue[0]}
        />

        {errors && <Form.Text className='text-danger'>{errors[`${panel[0]}`]}</Form.Text>}
      </Form.Group>
      <Form.Group className='mb-0 pt-2'>
        {errors[`opt_${panel[0]}`] && (
          <Form.Text className='text-danger'>{errors[`opt_${panel[0]}`]}</Form.Text>
        )}
        <OptionSection index={index} editorConfig={editorConfig} />
      </Form.Group>
    </div>
  );
};

export default MCQFormBodyComponent;
