import React from 'react';
import { Form } from 'react-bootstrap';

interface MarginComponentInterface {
  top: string;
  bottom: string;
  SetBottom: Function;
  SetTop: Function;
}

const MarginComponent = (props: MarginComponentInterface) => {
  const { top, bottom, SetBottom, SetTop } = props;

  const handleMarginValue = (event: React.ChangeEvent, type: string) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 40) return;
    switch (type) {
      case 'top':
        SetTop({
          marginTop: value,
        });
        break;
      case 'bottom':
        SetBottom({
          marginBottom: value,
        });
    }
  };
  return (
    <Form.Group data-testid='Margin'>
      <Form.Label className='design-section-text mb-3'>Margin (px)</Form.Label>
      <div className='margin-group'>
        <div className='margin-input-container'>
          <Form.Label htmlFor='margin-top-input' className='margin-top-label m-0'>
            Top
          </Form.Label>
          <Form.Control
            className='margin-top-input enable-spinner'
            id='margin-top-input'
            type='number'
            min='0'
            max='40'
            step='1'
            inputMode='numeric'
            name='fontSize'
            size='sm'
            value={top}
            onChange={(e) => handleMarginValue(e, 'top')}
          />
        </div>
        <div className='margin-input-container'>
          <Form.Label htmlFor='margin-bottom-input' className='margin-bottom-label m-0'>
            Bottom
          </Form.Label>
          <Form.Control
            className='margin-bottom-input enable-spinner'
            id='margin-bottom-input'
            type='number'
            min='0'
            max='40'
            name='fontSize'
            size='sm'
            value={bottom}
            onChange={(e) => handleMarginValue(e, 'bottom')}
          />
        </div>
      </div>
    </Form.Group>
  );
};

export default MarginComponent;
