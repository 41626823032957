/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
import { ReactComponent as AccordionExpand } from '../../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../../assets/icons/accordionHide.svg';
import ColorPickerComponent from '../../customizeComponentLayout/colorPicker';
import FontHandlerComponent from '../../customizeComponentLayout/fontHandler';
import { ConnectedProps, connect } from 'react-redux';
import { customStyles } from '../../customizeComponentLayout/styles';
import { fontColor, fontSize, setBgColor, setHotspotIcon } from '../../../redux/form/form.actions';
import { svgIconValue } from '../../Interactives/hotspot/svgIcon';

const mapState = ({ form }: any) => ({
  fontSize: form.fontSize,
  bgcolor: form.bgcolor,
  fontColor: form.fontColor,
  hotspotIcon: form.hotspotIcon,
});

const mapDispatch = (dispatch: Function) => ({
  SetColor: (form: {}) => dispatch(setBgColor(form)),
  SetBgColor: (form: {}) => dispatch(fontColor(form)),
  SetSize: (form: {}) => dispatch(fontSize(form)),
  SetIcon: (form: {}) => dispatch(setHotspotIcon(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const HotspotCustomize = (props: Props) => {
  const { bgcolor, fontColor, fontSize, hotspotIcon, SetColor, SetBgColor, SetSize, SetIcon } =
    props;
  const [collapseID, setCollapseId] = useState('Icon');

  const setIconValue = (key: string, value: string) => {
    let iconValue = hotspotIcon;
    iconValue = { ...iconValue, draw: value, iconType: key };
    SetIcon({
      hotspotIcon: iconValue,
    });
  };

  const toggleCollapse = (id: string) => {
    setCollapseId(id !== collapseID ? id : '');
  };

  const transformSvg = (key: string) => {
    let value;
    switch (true) {
      case key === 'Question':
        value = 'translate(13 -2.632)';
        break;
      case key === 'Plus':
        value = 'translate(-3874.431 -423.915)';
        break;
      case key === 'Minus':
        value = 'translate(-3874.431 -424.06)';
        break;
      case key === 'Information':
        value = 'translate(16.5 64.5)';
        break;
      default:
        value = 'translate(3 58.214)';
        break;
    }
    return value;
  };
  return (
    <Accordion className='mx-0' defaultActiveKey='Icon'>
      <Accordion.Item eventKey='Icon' className='border-0 my-3'>
        <Accordion.Header
          as='div'
          className={`custom-header-title m-0 ${collapseID === 'Icon' && 'selected-section'}`}
          onClick={() => toggleCollapse('Icon')}
        >
          {collapseID === 'Icon' ? <AccordionExpand /> : <AccordionHide />}
          <div className={`px-2 ${collapseID === 'Icon' && 'selected-section'}`}>Icon</div>
        </Accordion.Header>
        <Accordion.Body className='pb-0 px-2 pt-3'>
          <Form.Group className='mb-3'>
            <Form.Label className='design-section-text'>Type</Form.Label>
            <div className='d-flex'>
              {Object.entries(customStyles.icons).map(([key, value]) => {
                const transform = transformSvg(key);
                return (
                  <Form.Group key={key} id={key}>
                    <input
                      type='radio'
                      name='icon'
                      value={value}
                      onChange={(event) => setIconValue(key, event.target.value)}
                      checked={hotspotIcon.draw === value}
                      className='card-input-element'
                    />
                    <Card className='card-input' onClick={() => setIconValue(key, value)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='25'
                        viewBox='0 0 30 30'
                      >
                        <g transform={key === 'Question' ? 'translate(-3 1)' : 'translate(-3 -58)'}>
                          <g
                            transform={key === 'Question' ? 'translate(3 -1)' : 'translate(3 58)'}
                            fill={hotspotIcon.draw !== value ? '#ffffff' : '#112299'}
                          >
                            <path d={svgIconValue.outerCircle} />
                            <path
                              d={svgIconValue.innerCircle}
                              fill={hotspotIcon.draw !== value ? '#112299' : '#ffffff'}
                            />
                          </g>
                          <path
                            d={value}
                            transform={transform}
                            fill={hotspotIcon.draw !== value ? '#112299' : '#ffffff'}
                          />
                        </g>
                      </svg>
                    </Card>
                  </Form.Group>
                );
              })}
            </div>
          </Form.Group>
          <FontHandlerComponent
            componentType='Size'
            setFontProperty={SetSize}
            fontPropertyValue={fontSize}
            options={customStyles.fontSize}
          />
          <ColorPickerComponent type='bgColor' title='Color' SetColor={SetColor} color={bgcolor} />
          <ColorPickerComponent
            type='fontColor'
            title='Background'
            SetColor={SetBgColor}
            color={fontColor}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default connector(HotspotCustomize);
