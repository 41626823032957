import React from 'react';
import styled from 'styled-components';
import { Dropdown, Tooltip } from '@crystaldelta/loree-ui-components';
import { EditorMenuButton } from './styledContents';
import { TextIndentButtonProps } from './lintEditorType';
import { ReactComponent as SvgDecreaseIndent } from '../editorFormattingIcons/decreaseIndentIcon.svg';
import { ReactComponent as SvgDecreaseTextIndent } from '../editorFormattingIcons/decreaseTextIndentIcon.svg';
import { ReactComponent as SvgIncreaseIndent } from '../editorFormattingIcons/increaseIndentIcon.svg';
import { ReactComponent as SvgIncreaseTextIndent } from '../editorFormattingIcons/increaseTextIndentIcon.svg';
import { ReactComponent as SvgTextIndentIcon } from '../editorFormattingIcons/indentButton.svg';
import { IconDiv } from './utilityFunctions';

const TextIndentMenu = styled(Dropdown)`
  background-color: white;
  z-index: 10;
  width: 167px;
  height: 137px;
  border-radius: 4px;
  border: 1px solid #112299;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IndentButton = styled.button`
  display: flex;
  flex: 1;
  background: transparent;
  border: 0;
  width: 159px;
  height: 29px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: Source Sans Pro;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  &:hover:not([disabled]) {
    background: ${(props) => (props.disabled ? 'transparent' : '#112299')};
    color: ${(props) => (props.disabled ? '#999' : '#ffffff')};
    fill: ${(props) => (props.disabled ? '#999' : '#ffffff')};
    stroke-opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }
  &:focus {
    outline: none;
  }
  svg {
    width: 15px;
    height: 15px;
    object-fit: contain;
    color: #000;
  }
  &[disabled] {
    svg {
      fill: #b7b7b7;
    }
  }
`;

export const TextIndentButton = (props: TextIndentButtonProps) => {
  const { setIndentSpacing, getLeftPadding, getTextIndent, selectionTypeName } = props;
  return (
    <>
      <TextIndentMenu
        element={
          <EditorMenuButton data-testid='indent-icon'>
            <Tooltip info='Indent Spacing' data-testid='text-indent'>
              <IconDiv>
                <SvgTextIndentIcon />
              </IconDiv>
            </Tooltip>
          </EditorMenuButton>
        }
      >
        <IndentButton
          onClick={() => {
            setIndentSpacing('paragraphIndent', 'increase', selectionTypeName);
          }}
          data-testid='increase-indent'
        >
          <SvgIncreaseIndent />
          Increase indent
        </IndentButton>

        <IndentButton
          onClick={() => {
            setIndentSpacing('paragraphIndent', 'decrease', selectionTypeName);
          }}
          disabled={getLeftPadding(selectionTypeName) === '0'}
          data-testid='decrease-indent'
        >
          <SvgDecreaseIndent />
          Decrease indent
        </IndentButton>
        <IndentButton
          onClick={() => {
            setIndentSpacing('textIndent', 'increase', selectionTypeName);
          }}
          data-testid='increase-text-indent'
        >
          <SvgIncreaseTextIndent />
          Increase text indent
        </IndentButton>
        <IndentButton
          onClick={() => {
            setIndentSpacing('textIndent', 'decrease', selectionTypeName);
          }}
          disabled={getTextIndent(selectionTypeName) === '0'}
          data-testid='decrease-text-indent
        '
        >
          <SvgDecreaseTextIndent />
          Decrease text indent
        </IndentButton>
      </TextIndentMenu>
    </>
  );
};
