import React from 'react';
import { DropdownColorPicker } from '@crystaldelta/loree-ui-color-picker';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { ReactComponent as BorderColorPicker } from '../editorFormattingIcons/borderColorButton.svg';
import { EditorMenuButton } from '../editorUtilityFunctions/styledContents';
import { BorderColorPickerPanel } from './borderColorPickerPanel';
import { BorderColorButtonProps } from '../editorUtilityFunctions/lintEditorType';
import { IconDiv } from '../editorUtilityFunctions/utilityFunctions';

export function BorderColorButton(props: BorderColorButtonProps) {
  const {
    currentColor,
    onChange,
    currentSize,
    onChangeSize,
    currentStyle,
    onChangeStyle,
    globalColorList,
    sharedColors,
    colorPickerFeatures,
    colorPickerType,
    colorContrastRatio,
  } = props;

  return (
    <DropdownColorPicker
      onChange={onChange}
      currentColor={currentColor ?? ''}
      element={
        <EditorMenuButton data-testid='border-color-btn'>
          <Tooltip info='Border Color'>
            <IconDiv>
              <BorderColorPicker />
            </IconDiv>
          </Tooltip>
        </EditorMenuButton>
      }
      topExtraElement={
        <BorderColorPickerPanel {...{ currentSize, currentStyle, onChangeSize, onChangeStyle }} />
      }
      globalColorList={globalColorList}
      sharedColors={sharedColors}
      colorPickerFeatures={colorPickerFeatures}
      colorPickerType={colorPickerType}
      colorContrastRatio={colorContrastRatio}
    />
  );
}
