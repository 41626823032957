/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import ColorPickerComponent from '../../customizeComponentLayout/colorPicker';
import { ReactComponent as AccordionExpand } from '../../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../../assets/icons/accordionHide.svg';
import { customStyles } from '../../customizeComponentLayout/styles';
import AlignmentComponent from '../../customizeComponentLayout/alignment';
import '../customize.scss';
import { ConnectedProps, connect } from 'react-redux';
import {
  customPaddingbottom,
  customPaddingleft,
  customPaddingright,
  customPaddingtop,
  fontColor,
  fontSize,
  fontType,
  setAlignment,
  setBtnColor,
  setBtnType,
  inactivefontColor,
} from '../../../redux/button/button.actions';
import SpaceComponent from '../../customizeComponentLayout/spaceComponent';
import FontHandlerComponent from '../../customizeComponentLayout/fontHandler';

const mapState = ({ button }: any) => ({
  color: button.color,
  btnType: button.btnType,
  fontFamily: button.fontFamily,
  fontSize: button.fontSize,
  alignment: button.alignment,
  fontColor: button.fontColor,
  errors: button.errors,
  customPaddingtop: button.customPaddingtop,
  customPaddingleft: button.customPaddingleft,
  customPaddingbottom: button.customPaddingbottom,
  customPaddingright: button.customPaddingright,
});

const mapDispatch = (dispatch: Function) => ({
  SetButtonColor: (data: {}) => dispatch(setBtnColor(data)),
  SetButtonType: (data: {}) => dispatch(setBtnType(data)),
  SetFontFamily: (data: {}) => dispatch(fontType(data)),
  SetFontSize: (data: {}) => dispatch(fontSize(data)),
  Setalignment: (data: {}) => dispatch(setAlignment(data)),
  SetFontColor: (data: {}) => dispatch(fontColor(data)),
  SetInactiveFontColor: (data: {}) => dispatch(inactivefontColor(data)),
  SetcustomPaddingtop: (data: {}) => dispatch(customPaddingtop(data)),
  SetcustomPaddingleft: (data: {}) => dispatch(customPaddingleft(data)),
  SetcustomPaddingbottom: (data: {}) => dispatch(customPaddingbottom(data)),
  SetcustomPaddingright: (data: {}) => dispatch(customPaddingright(data)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = { fontFamilyPropsList: any } & ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const ButtonCustomize = (props: Props) => {
  const [opened, setOpened] = useState('button');

  const toggleCollapse = (id: string) => {
    setOpened((prevAccordion: string) => (prevAccordion === id ? '' : id));
  };

  const getButtonDisplayType = () => {
    switch (props.btnType) {
      case 'btn btn-square btn-dark':
        return 'Fill';
      case 'btn btn-outline-dark':
        return 'Outline';
      case 'btn btn-pill btn-dark':
        return 'Pill';
      default:
        return '';
    }
  };

  return (
    <Accordion className='designsec-accordion' defaultActiveKey='button'>
      <Accordion.Item eventKey='button' className='border-0 my-3'>
        <Accordion.Header
          as='div'
          className={`custom-header-title m-0 ${opened === 'button' && 'selected-section'}`}
          onClick={() => toggleCollapse('button')}
        >
          {opened === 'button' ? <AccordionExpand /> : <AccordionHide />}
          <div className={`px-2 ${opened === 'button' && 'selected-section'} section-header-title`}>
            Button
          </div>
        </Accordion.Header>
        <Accordion.Body className='px-2'>
          <FontHandlerComponent
            componentType='Type'
            setFontProperty={props.SetButtonType}
            fontPropertyValue={getButtonDisplayType()}
            options={customStyles.buttonType}
          />
          <AlignmentComponent
            alignmentValue={props.alignment}
            SetAlignmentValue={props.Setalignment}
          />
          <SpaceComponent
            componentType='Padding'
            left={props.customPaddingleft}
            top={props.customPaddingtop}
            right={props.customPaddingright}
            bottom={props.customPaddingbottom}
            SetLeft={props.SetcustomPaddingleft}
            SetTop={props.SetcustomPaddingtop}
            SetRight={props.SetcustomPaddingright}
            SetBottom={props.SetcustomPaddingbottom}
          />
          <FontHandlerComponent
            componentType='Font Family'
            setFontProperty={props.SetFontFamily}
            fontPropertyValue={props.fontFamily}
            options={customStyles.fontFamily}
            customFonts={props.fontFamilyPropsList}
          />
          <FontHandlerComponent
            componentType='Font Size'
            setFontProperty={props.SetFontSize}
            fontPropertyValue={props.fontSize}
            options={customStyles.fontSize}
          />
          <ColorPickerComponent
            type='btnFontColor'
            title='Font Color'
            SetColor={props.SetFontColor}
            color={props.fontColor}
          />
          <ColorPickerComponent
            type='btnbgColor'
            title='Background Color'
            SetColor={props.SetButtonColor}
            color={props.color}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default connector(ButtonCustomize);
