import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as GlobalIcon } from '../../../src/assets/icons/globalIcon.svg';
import { ReactComponent as PrivateIcon } from '../../../src/assets/icons/sharedIcon.svg';
import { ReactComponent as SharedIcon } from '../../../src/assets/icons/privateIcon.svg';
import { ReactComponent as CreateIcon } from '../../../src/assets/icons/createIcon.svg';

interface IconData {
  className: string;
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pathname: string;
}

const icons: IconData[] = [
  {
    className: 'createIcon',
    IconComponent: CreateIcon,
    pathname: `/interactives/${sessionStorage.getItem('user')}/${sessionStorage.getItem(
      'org_id',
    )}/${sessionStorage.getItem('loreeVersion')}`,
  },
  {
    className: 'globalIcon',
    IconComponent: GlobalIcon,
    pathname: `/global/${sessionStorage.getItem('org_id')}`,
  },
  {
    className: 'privateIcon',
    IconComponent: PrivateIcon,
    pathname: `/Contents/${sessionStorage.getItem('user')}/${sessionStorage.getItem('user_id')}`,
  },
  {
    className: 'sharedIcon',
    IconComponent: SharedIcon,
    pathname: '/shared',
  },
];

const IconsSidebar = () => {
  const history = useHistory();
  const location = useLocation();  
  const renderIcon = ({ className, IconComponent, pathname }: IconData) => {
    const isActive = location.pathname === pathname;
    return (
      <div
        key={className}
        className={`${className}Div ${isActive ? 'active' : ''}`}
        role='button'
        tabIndex={0}
        onClick={() => history.push(pathname)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            history.push(pathname);
          }
        }}
      >
        <div className={className}>
          <IconComponent width={20} height={20} />
        </div>
      </div>
    );
  };
  
  const filteredIcons = location.pathname.startsWith('/interactives/')
    ? icons.filter((icon) => icon.className !== 'createIcon')
    : icons;
  return <div className='left-content'>{filteredIcons.map((icon) => renderIcon(icon))}</div>;
};

export default IconsSidebar;
