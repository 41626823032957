/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ComponentCustomize from './customize';
import ButtonCustomize from './button/customize';
import { customStyles } from '../customizeComponentLayout/styles';
import { FormInterface } from '../../redux/interface';
import FlipCardAndClickAndRevealCustomize from './FlipCardAndClick&Reveal/index';
import store from '../../redux/store';
import { getMcqEditData } from './multipleChoice/utils';
import { isEmpty } from './validate';
import MultipleChoiceCustomizeSection from './multipleChoice/customizeSection';
import DragAndDropCustomize from './DndAndHotspot/dndCustomize';
import HotspotCustomize from './DndAndHotspot/hotspotCustomize';
import { getElementById } from '@crystaldelta/loree-util-dom';

export interface Customisation {
  borderType: string;
  borderSize: string;
  fontColor: string;
  fontHeadings: string;
  fontWeight: string;
  fontSize: string;
  fontFamily: string;
  quFontSize: string;
  quFontWeight: string;
  opFontSize: string;
  opFontWeight: string;
}

export const getCustomizeComponent = (props: any, type: string) => {
  const actionType = props.match?.params?.id ? 'edit' : 'create';
  switch (type) {
    case 'Accordion':
    case 'Tabs':
    case 'imageslider':
      return (
        <ComponentCustomize
          content={props}
          contentType={type}
          fontFamilyPropsList={props.fontFamilyPropsList}
          customHeaderStylesPropsList={props.customHeaderStylesPropsList}
        />
      );
    case 'Button':
      return <ButtonCustomize fontFamilyPropsList={props.fontFamilyPropsList} />;
    case 'ClickAndRevealInteractions':
    case 'flipcard':
      return (
        <FlipCardAndClickAndRevealCustomize
          type={type}
          fontFamilyPropsList={props.fontFamilyPropsList}
        />
      );
    case 'multiplechoice':
      return (
        <MultipleChoiceCustomizeSection
          fontFamilyPropsList={props.fontFamilyPropsList}
          customHeaderStylesPropsList={props.customHeaderStylesPropsList}
          id={props.match?.params?.id}
        />
      );
    case 'DragandDrop':
      return (
        <DragAndDropCustomize
          actionType={actionType}
          fontFamilyPropsList={props.fontFamilyPropsList}
        />
      );
    case 'Hotspot':
      return <HotspotCustomize />;
  }
};

export const getLibraryId = (type: string) => {
  switch (type) {
    case 'Accordion':
      return 1;
    case 'Tabs':
      return 2;
    case 'Button':
      return 3;
    case 'multiplechoice':
      return 4;
    case 'imageslider':
      return 6;
    case 'ClickAndRevealInteractions':
      return 7;
    case 'DragandDrop':
      return 8;
    case 'Hotspot':
      return 9;
    case 'flipcard':
      return 5;
  }
};

export const getComponentCustomValues = (type: string, props: any) => {
  switch (type) {
    case 'ClickAndRevealInteractions':
      return getClickAndRevealCustomValue(props);
    case 'multiplechoice':
      return getMCQCustomValue();
    case 'flipcard':
      return getFlipCardCustomValues(props);
    default:
      return getCustomValues(props);
  }
};

const getMCQCustomValue = () => {
  const mcqDataCollection = store.getState().mcqform;
  return {
    optionType: mcqDataCollection.optiontype,
    retry: mcqDataCollection.customtryagain,
    checkAnswer: mcqDataCollection.checkedAnswer,
    shuffle: mcqDataCollection.shuffle,
    pbBgColor: mcqDataCollection.pbBgColor,
    pbFontColor: mcqDataCollection.pbFontColor,
    bgColor: mcqDataCollection.bgColor,
    opBgColor: mcqDataCollection.opBgColor,
    borderSize: mcqDataCollection.borderSize,
    borderStyle: mcqDataCollection.borderStyle,
    borderColor: mcqDataCollection.borderColor,
    borderType: mcqDataCollection.borderType,
    borderRadius: mcqDataCollection.borderRadius,
    borderTop: mcqDataCollection.borderTop,
    borderRight: mcqDataCollection.borderRight,
    borderLeft: mcqDataCollection.borderLeft,
    borderBottom: mcqDataCollection.borderBottom,
    buttonFontColor: mcqDataCollection.buttonFontColor,
    buttonBgColor: mcqDataCollection.buttonBgColor,
    fontFamily: mcqDataCollection.fontFamily,
    fontSize: mcqDataCollection.fontSize,
    fontWeight: mcqDataCollection.fontWeight,
    pbFontHeadings: mcqDataCollection.pbFontHeadings,
    pbFontFamily: mcqDataCollection.pbFontFamily,
    pbFontSize: mcqDataCollection.pbFontSize,
    pbFontWeight: mcqDataCollection.pbFontWeight,
    alignment: mcqDataCollection.alignment,
    btnType: mcqDataCollection.btnType,
    customPaddingtop: mcqDataCollection.customPaddingtop,
    customPaddingleft: mcqDataCollection.customPaddingleft,
    customPaddingbottom: mcqDataCollection.customPaddingbottom,
    customPaddingright: mcqDataCollection.customPaddingright,
  };
};

const getClickAndRevealCustomValue = (props: FormInterface) => {
  return {
    clickBorderRadius: props.clickBorderRadius,
    clickBorderTop: props.clickBorderTop,
    clickBorderRight: props.clickBorderRight,
    clickBorderLeft: props.clickBorderLeft,
    clickBorderBottom: props.clickBorderBottom,
    clickBorderColor: props.clickBorderColor,
    clickBorderType: props.clickBorderType,
    clickBgColor: props.clickBgColor,
    revealBorderRadius: props.revealBorderRadius,
    revealBorderTop: props.revealBorderTop,
    revealBorderRight: props.revealBorderRight,
    revealBorderLeft: props.revealBorderLeft,
    revealBorderBottom: props.revealBorderBottom,
    revealBorderColor: props.revealBorderColor,
    revealBorderType: props.revealBorderType,
    revealBgColor: props.revealBgColor,
    clickPaddingTop: props.clickPaddingTop,
    clickPaddingRight: props.clickPaddingRight,
    clickPaddingLeft: props.clickPaddingLeft,
    clickPaddingBottom: props.clickPaddingBottom,
    revealPaddingTop: props.revealPaddingTop,
    revealPaddingRight: props.revealPaddingRight,
    revealPaddingLeft: props.revealPaddingLeft,
    revealPaddingBottom: props.revealPaddingBottom,
  };
};

const getCustomValues = (props: any) => {
  return {
    fontHeadings: props.fontHeadings,
    borderTop: props.borderTop,
    borderRight: props.borderRight,
    borderLeft: props.borderLeft,
    borderBottom: props.borderBottom,
    borderRadius: props.borderRadius,
    fontFamily: props.fontFamily,
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    radius: props.radius,
    icon: props.icon,
    bgcolor: props.bgcolor,
    alignment: props.alignment,
    fontColor: props.fontColor,
    borderType: props.borderType,
    borderColor: props.borderColor,
    borderSize: props.borderSize,
    borderStyle: props.borderStyle,
    setcaptionAlignment: props.setcaptionAlignment,
    iconColor: props.iconColor,
    iconSize: props.iconSize,
    iconAlignment: props.iconAlignment,
    iconType: props.iconType,
    imageBgColor: props.imageBgColor,
    dndLayout: props.dndLayout,
    dragAreaType: props.dragAreaType,
    paddingTop: props.paddingTop !== '' ? props.paddingTop : '0',
    paddingLeft: props.paddingLeft !== '' ? props.paddingLeft : '0',
    paddingBottom: props.paddingBottom !== '' ? props.paddingBottom : '0',
    paddingRight: props.paddingRight !== '' ? props.paddingRight : '0',
    dropZoneBgColor: props.dropzoneBgColor,
    dropZoneOpacity: props.dropzoneOpacity,
    hotspotIcon: props.hotspotIcon,
    btnPaddingTop: props.btnPaddingTop !== '' ? props.btnPaddingTop : '0',
    btnPaddingLeft: props.btnPaddingLeft !== '' ? props.btnPaddingLeft : '0',
    btnPaddingBottom: props.btnPaddingBottom !== '' ? props.btnPaddingBottom : '0',
    btnPaddingRight: props.btnPaddingRight !== '' ? props.btnPaddingRight : '0',
    btnFontColor: props.btnFontColor,
    btnFontWeight: props.btnFontWeight,
    btnFontSize: props.btnFontSize,
    btnFontFamily: props.btnFontFamily,
    btnBgColor: props.btnBgColor,
    btnType: props.btnType,
    marginBottom: props.marginBottom,
    marginTop: props.marginTop,
    displayActiveColorPicker: props.displayActiveColorPicker,
    inactivefontColor: props.inactivefontColor,
    separatorColor: props.separatorColor,
    separatorWidth: props.separatorWidth,
    noOfLayout: props.noOfLayout,
    fixedLayout: props.fixedLayout,
  };
};
const getFlipCardCustomValues = (props: any) => {
  return {
    bgcolor: props.flipCardBgcolor,
    backColor: props.backColor,
    height: props.height,
    noOfCard: props.noOfCard,
    radius: props.flipCardRadius,
    borderType: props.flipCardBorderType,
    borderColor: props.flipCardBorderColor,
    borderSize: props.flipCardBorderSize,
    borderStyle: props.flipCardBorderStyle,
    backBorderRadius: props.backBorderRadius,
    frontBorderSizeRight: props.frontBorderSizeRight,
    frontBorderSizeTop: props.frontBorderSizeTop,
    frontBorderSizeBottom: props.frontBorderSizeBottom,
    backBorderSizeLeft: props.backBorderSizeLeft,
    backBorderSizeRight: props.backBorderSizeRight,
    backBorderSizeTop: props.backBorderSizeTop,
    backBorderSizeBottom: props.backBorderSizeBottom,
    backBorderStyle: props.backBorderStyle,
    backBorderColor: props.backBorderColor,
    flip: props.flip,
    cardType: props.cardType,
    frontTop: props.frontTop,
    frontRight: props.frontRight,
    frontBottom: props.frontBottom,
    frontLeft: props.frontLeft,
    backTop: props.backTop,
    backRight: props.backRight,
    backBottom: props.backBottom,
    backLeft: props.backLeft,
    frontTextColor: props.frontTextColor,
    backTextColor: props.backTextColor,
    fontFamily: props.flipCardFontFamily,
    frontHeader: props.frontHeader,
    frontSize: props.frontSize,
    frontWeight: props.frontWeight,
    backHeader: props.backHeader,
    backSize: props.backSize,
    backWeight: props.backWeight,
  };
};

export const setEditValue = (
  type: string,
  data: any,
  contentArr: any,
  parameters: any,
  pathname: string,
  MakeEdit: Function,
) => {
  const editData =
    type !== 'multiplechoice'
      ? getEditData(data, parameters, contentArr, type, pathname)
      : getMcqEditData(data, parameters, contentArr, pathname);
  MakeEdit(editData);
};

const getEditData = (
  data: any,
  parameters: any,
  contentArr: any,
  type: string,
  pathname: string,
) => {
  const { customisations } = data;
  const applyOldBorder = customisations.borderType !== '' ? customisations.borderSize : 0;
  return {
    marginBottom: customisations.marginBottom ? customisations.marginBottom : '0',
    marginTop: customisations.marginTop ? customisations.marginTop : '0',
    borderTop: customisations.borderTop ? customisations.borderTop : applyOldBorder,
    borderBottom: customisations.borderBottom ? customisations.borderBottom : applyOldBorder,
    borderLeft: customisations.borderLeft ? customisations.borderLeft : applyOldBorder,
    borderRight: customisations.borderRight ? customisations.borderRight : applyOldBorder,
    borderRadius: customisations.borderRadius ? customisations.borderRadius : '0',
    title: pathname.includes('duplicate') ? '' : data.title,
    contentArr: contentArr,
    parameters:
      type === 'ClickAndRevealInteractions'
        ? migrateTitleContent(parameters, customisations)
        : parameters,
    type: data.type,
    radius: getDefaultRadius(customisations.radius),
    fontHeadings: customisations.fontHeadings,
    fontFamily: customisations.fontFamily ? customisations.fontFamily : 'Helvetica',
    fontSize: customisations.fontSize !== '' ? customisations.fontSize : '16',
    fontWeight: customisations.fontWeight ? customisations.fontWeight : 'Normal',
    bgcolor: customisations.bgcolor ? customisations.bgcolor : '#F2F2F2',
    icon: customisations.icon,
    alignment: customisations.alignment,
    fontColor: customisations.fontColor ? customisations.fontColor : '#000000',
    borderType: customisations.borderType,
    borderColor: customisations.borderColor,
    borderSize: customisations.borderSize ? customisations.borderSize : '1',
    borderStyle: customisations.borderStyle ? customisations.borderStyle : 'solid',
    setcaptionAlignment: customisations.setcaptionAlignment,
    iconColor: customisations.iconColor,
    iconSize: customisations.iconSize,
    iconAlignment: customisations.iconAlignment,
    iconType: customisations.iconType,
    imageBgColor: customisations.imageBgColor ? customisations.imageBgColor : '#ffffff',
    dndLayout: customisations.dndLayout,
    dndParameters: parameters,
    dndArray: contentArr,
    dragAreaType: customisations.dragAreaType
      ? customisations.dragAreaType
      : 'btn btn-square btn-dark',
    paddingTop: customisations.paddingTop ? customisations.paddingTop : '10',
    paddingLeft: customisations.paddingLeft ? customisations.paddingLeft : '10',
    paddingBottom: customisations.paddingBottom ? customisations.paddingBottom : '10',
    paddingRight: customisations.paddingRight ? customisations.paddingRight : '10',
    dropZoneBgColor: customisations.dropZoneBgColor ? customisations.dropZoneBgColor : '#F2F2F2',
    dropZoneOpacity: customisations.dropZoneOpacity ? customisations.dropZoneOpacity : '0.5',
    hotspotIcon: customisations.hotspotIcon
      ? customisations.hotspotIcon
      : {
          draw: 'M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm1.2,0A13…1,0,2.709H16.355v4.791a1.355,1.355,0,0,1-2.709,0Z',
          iconType: 'Plus',
        },
    btnPaddingTop: customisations.btnPaddingTop ? customisations.btnPaddingTop : '10',
    btnPaddingLeft: customisations.btnPaddingLeft ? customisations.btnPaddingLeft : '10',
    btnPaddingBottom: customisations.btnPaddingBottom ? customisations.btnPaddingBottom : '10',
    btnPaddingRight: customisations.btnPaddingRight ? customisations.btnPaddingRight : '10',
    btnFontColor: customisations.btnFontColor ? customisations.btnFontColor : '#ffffff',
    btnFontWeight: customisations.btnFontWeight ? customisations.btnFontWeight : 'Normal',
    btnFontSize: customisations.btnFontSize ? customisations.btnFontSize : '16',
    btnFontFamily: customisations.btnFontFamily ? customisations.btnFontFamily : 'Helvetica',
    btnBgColor: customisations.btnBgColor ? customisations.btnBgColor : '#1D77CC',
    btnType: customisations.btnType ? customisations.btnType : 'btn btn-square btn-dark',
    backColor: customisations.backColor,
    backBorderRadius: customisations.backBorderRadius
      ? customisations.backBorderRadius
      : getDefaultRadius(customisations.backBorderRadius),
    frontBorderSizeRight: customisations.frontBorderSizeRight
      ? customisations.frontBorderSizeRight
      : getDefaultBorderValue(customisations, 'right'),
    frontBorderSizeTop: customisations.frontBorderSizeTop
      ? customisations.frontBorderSizeTop
      : getDefaultBorderValue(customisations, 'top'),
    frontBorderSizeBottom: customisations.frontBorderSizeBottom
      ? customisations.frontBorderSizeBottom
      : getDefaultBorderValue(customisations, 'bottom'),
    backBorderSizeLeft: customisations.backBorderSizeLeft
      ? customisations.backBorderSizeLeft
      : getDefaultBorderValue(customisations, 'left'),
    backBorderSizeRight: customisations.backBorderSizeRight
      ? customisations.backBorderSizeRight
      : getDefaultBorderValue(customisations, 'right'),
    backBorderSizeTop: customisations.backBorderSizeTop
      ? customisations.backBorderSizeTop
      : getDefaultBorderValue(customisations, 'top'),
    backBorderSizeBottom: customisations.backBorderSizeBottom
      ? customisations.backBorderSizeBottom
      : getDefaultBorderValue(customisations, 'bottom'),
    backBorderStyle: customisations.backBorderStyle ? customisations.backBorderStyle : 'solid',
    backBorderColor: customisations.backBorderColor ? customisations.backBorderColor : '#000000',
    height: customisations.height,
    noOfCard: customisations.noOfCard,
    noOfLayout: customisations.noOfLayout,
    fixedLayout: customisations.fixedLayout,
    flip: customisations.flip,
    frontTop: customisations.frontTop ? customisations.frontTop : '25',
    frontRight: customisations.frontRight ? customisations.frontRight : '25',
    frontBottom: customisations.frontBottom ? customisations.frontBottom : '0',
    frontLeft: customisations.frontLeft ? customisations.frontLeft : '25',
    backTop: customisations.backTop ? customisations.backTop : '25',
    backRight: customisations.backRight ? customisations.backRight : '25',
    backBottom: customisations.backBottom ? customisations.backBottom : '0',
    backLeft: customisations.backLeft ? customisations.backLeft : '25',
    frontTextColor: customisations.frontTextColor ? customisations.frontTextColor : '#000000',
    backTextColor: customisations.backTextColor ? customisations.backTextColor : '#000000',
    cardType: customisations.cardType ? customisations.cardType : 'square',
    frontHeader: customisations.frontHeader ? customisations.frontHeader : '',
    frontSize: customisations.frontSize ? customisations.frontSize : 16,
    frontWeight: customisations.frontWeight ? customisations.frontWeight : 'Normal',
    backHeader: customisations.backHeader ? customisations.backHeader : '',
    backSize: customisations.backSize ? customisations.backSize : 16,
    backWeight: customisations.backWeight ? customisations.backWeight : 'Normal',
    clickBorderRadius: customisations.clickBorderRadius
      ? customisations.clickBorderRadius
      : getDefaultRadius(customisations.radius),
    clickBorderTop: customisations.clickBorderTop
      ? customisations.clickBorderTop
      : getDefaultBorderValue(customisations, 'top'),
    clickBorderRight: customisations.clickBorderRight
      ? customisations.clickBorderRight
      : getDefaultBorderValue(customisations, 'right'),
    clickBorderLeft: customisations.clickBorderLeft
      ? customisations.clickBorderLeft
      : getDefaultBorderValue(customisations, 'left'),
    clickBorderBottom: customisations.clickBorderBottom
      ? customisations.clickBorderBottom
      : getDefaultBorderValue(customisations, 'bottom'),
    clickBorderColor: customisations.clickBorderColor
      ? customisations.clickBorderColor
      : customisations.borderColor,
    clickBorderType: customisations.clickBorderType
      ? customisations.clickBorderType
      : customisations.borderStyle,
    clickBgColor: customisations.clickBgColor ? customisations.clickBgColor : '#ffffff',
    revealBorderRadius: customisations.revealBorderRadius
      ? customisations.revealBorderRadius
      : getDefaultRadius(customisations.radius),
    revealBorderTop: customisations.revealBorderTop
      ? customisations.revealBorderTop
      : getDefaultBorderValue(customisations, 'top'),
    revealBorderRight: customisations.revealBorderRight
      ? customisations.revealBorderRight
      : getDefaultBorderValue(customisations, 'right'),
    revealBorderLeft: customisations.revealBorderLeft
      ? customisations.revealBorderLeft
      : getDefaultBorderValue(customisations, 'left'),
    revealBorderBottom: customisations.revealBorderBottom
      ? customisations.revealBorderBottom
      : getDefaultBorderValue(customisations, 'bottom'),
    revealBorderColor: customisations.revealBorderColor
      ? customisations.revealBorderColor
      : customisations.borderColor,
    revealBorderType: customisations.revealBorderType
      ? customisations.revealBorderType
      : customisations.borderStyle,
    revealBgColor: customisations.revealBgColor ? customisations.revealBgColor : '#ffffff',
    clickPaddingTop: customisations.clickPaddingTop ? customisations.clickPaddingTop : '0',
    clickPaddingRight: customisations.clickPaddingRight ? customisations.clickPaddingRight : '0',
    clickPaddingLeft: customisations.clickPaddingLeft ? customisations.clickPaddingLeft : '0',
    clickPaddingBottom: customisations.clickPaddingBottom ? customisations.clickPaddingBottom : '0',
    revealPaddingTop: customisations.revealPaddingTop ? customisations.revealPaddingTop : '0',
    revealPaddingRight: customisations.revealPaddingRight ? customisations.revealPaddingRight : '0',
    revealPaddingLeft: customisations.revealPaddingLeft ? customisations.revealPaddingLeft : '0',
    revealPaddingBottom: customisations.revealPaddingBottom
      ? customisations.revealPaddingBottom
      : '0',
    displayActiveColorPicker: customisations.displayActiveColorPicker
      ? customisations.displayActiveColorPicker
      : '#FFFFFF',
    separatorColor: customisations.separatorColor ? customisations.separatorColor : '#000000',
    inactivefontColor: customisations.inactivefontColor
      ? customisations.inactivefontColor
      : '#000000',
    separatorWidth: customisations.separatorWidth ? customisations.separatorWidth : '0',
  };
};
export const getInteractiveTitle = (type: string, componentType?: string) => {
  switch (type) {
    case 'ClickAndRevealInteractions':
      return 'Click & Reveal Interactive';
    case 'imageslider':
      return 'Image/Video Slider';
    case 'multiplechoice':
      return 'Multiple Choice Question';
    case 'Tab':
      return 'Tabs';
    case 'DragandDrop':
      return 'Drag and Drop';
    case 'Hotspot':
      return 'Image Hotspot';
    case 'flipcard':
      return 'Flip Card';
    case '':
      return componentType ? 'Type of Interactive' : 'Normal';
    default:
      return type;
  }
};

export const migrateTitleContent = (parameters: any, customisation: Customisation) => {
  const refinedParameters = [...parameters];
  refinedParameters.forEach((element) => {
    const { title } = element;
    const isHtmlContent = title.startsWith('<') && title.endsWith('>');
    element.title = isHtmlContent
      ? element.title
      : refineTitleContentWithStyle(title, customisation);
  });
  return refinedParameters;
};

const refineTitleContentWithStyle = (content: string, customisations: Customisation) => {
  const { fontColor, fontHeadings, fontWeight, fontSize, fontFamily } = customisations;
  const wrapperTag = fontHeadings !== '' ? fontHeadings : 'p';
  const innerTag = fontWeight === 'Bold' ? 'strong' : 'span';
  const fontType = Object.keys(customStyles.editorNativeFonts).find((key: string) => {
    return (
      customStyles.editorNativeFonts[key as keyof typeof customStyles.editorNativeFonts] ===
      fontFamily
    );
  });
  return `<${wrapperTag}><${innerTag} class='ql-font-${fontType}' style='color:${fontColor}; font-size:${fontSize}px'>${content}</${innerTag}></${wrapperTag}>`;
};

export const getDefaultRadius = (radius: string) => {
  return radius === 'rounded-0' ? '0' : '8';
};

export const getDefaultBorderValue = (customisations: Customisation, type: string) => {
  const { borderType, borderSize } = customisations;
  switch (borderType) {
    case 'border-start border-dark':
      return type === 'left' ? borderSize : '0';
    case 'border-end border-dark':
      return type === 'right' ? borderSize : '0';
    case 'border-top border-dark':
      return type === 'top' ? borderSize : '0';
    case 'border-bottom border-dark':
      return type === 'bottom' ? borderSize : '0';
    case 'border border-dark':
      return borderSize;
    default:
      return '0';
  }
};

export const removeEmptyTags = (parameters: any[], type: string) => {
  if (type === 'multiplechoice') {
    removeEmptyTagsFromMcq(parameters);
    return;
  }
  parameters.forEach((content) => {
    const text = type !== 'flipcard' ? 'text' : 'fronttext';
    const title = type !== 'flipcard' ? 'title' : 'backtext';
    content[`${text}`] = isEmpty(content[`${text}`]) ? '' : content[`${text}`];
    content[`${title}`] = isEmpty(content[`${title}`]) ? '' : content[`${title}`];
  });
};

const removeEmptyTagsFromMcq = (parameters: any[]) => {
  parameters.forEach((content) => {
    content.question = isEmpty(content.question) ? '' : content.question;
    content.options.forEach((content: any) => {
      content.option = isEmpty(content.option) ? '' : content.option;
    });
  });
};

export const setContentArray = (
  parameters: any,
  type: string,
  SetValue: Function,
  dndLayout: string,
) => {
  let contentArray: any = [];
  if (type === 'multiplechoice') {
    let optionId = 0;
    parameters.forEach((el: any, index: number) => {
      let question = Object.keys(el);
      contentArray.push([`${question[0]}_${index}`, { options: [] }]);
      el.options.forEach((option: {}) => {
        let value = Object.keys(option);
        contentArray[index][1].options.push(`${value[0]}_${optionId}`);
        optionId = optionId + 1;
        SetValue({
          num: index + 1,
          option_id: optionId,
        });
      });
    });
  } else {
    parameters.forEach((el: any, i: number) => {
      if (el.alt) delete el.alt;
      const data = getContentArrayValue(type, dndLayout, i, 0);
      contentArray.push(data);
      SetValue({
        num: i + 1,
      });
    });
  }
  return contentArray;
};

export const getParamsValue = (type: string, dndLayout: string) => {
  const defaultParams = { title: '', text: '' };
  switch (type) {
    case 'flipcard':
      return { fronttext: '', backtext: '' };
    case 'multiplechoice':
      return {
        question: '',
        options: [
          { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
          { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
        ],
      };
    case 'DragandDrop':
      return dndLayout === 'CustomOverlay' ? { background: '', dropZone: [] } : defaultParams;
    case 'Hotspot':
      return { background: '', dropZone: [] };
    default:
      return defaultParams;
  }
};

export const getContentArrayValue = (
  type: string,
  dndLayout: string,
  number: number,
  optionId: number,
) => {
  const defaultParams = [`title_${number + 1}`, `text_${number + 1}`];
  switch (type) {
    case 'flipcard':
      return [`fronttext_${number + 1}`, `backtext_${number + 1}`];
    case 'multiplechoice':
      return [
        `question_${number + 1}`,
        {
          options: [`option_${optionId}`, `option_${optionId + 1}`],
        },
      ];
    case 'DragandDrop':
      return dndLayout === 'CustomOverlay'
        ? [`background_${number + 1}`, `text_${number + 1}`]
        : defaultParams;
    case 'Hotspot':
      return [`background_${number + 1}`, `text_${number + 1}`];
    default:
      return defaultParams;
  }
};

export const getButtonDisplayType = (btnType: string) => {
  switch (btnType) {
    case 'btn btn-square btn-dark':
      return 'Fill';
    case 'btn btn-outline-dark':
      return 'Outline';
    case 'btn btn-pill btn-dark':
      return 'Pill';
    default:
      return '';
  }
};

export const isFontNameAvailable = (name: string, fontFamilyPropsList: any) => {
  for (let i = 0; i < fontFamilyPropsList.length; i++) {
    if (fontFamilyPropsList[i].name === name) {
      return fontFamilyPropsList[i].fontFamily;
    }
  }
};

export const setFocus = (elementId: string) => {
  setTimeout(() => {
    getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, 500);
};
