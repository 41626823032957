import React from 'react';
import { Form, Button } from 'react-bootstrap';
import './customStyles.scss';
import { ShowColorPicker } from '../color_picker/generalColorPicker';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';
import { getElementsByClassName } from '@crystaldelta/loree-util-dom';

interface ColorPickerComponentInterface {
  color: string;
  SetColor: Function;
  type: string;
  title: string;
}

const getDefaultColor = (type: string, colorType: string) => {
  switch (type) {
    case 'click_bgColor':
    case 'reveal_bgColor':
    case 'frontcard_bgColor':
    case 'backcard_bgColor':
      return CONSTANTS.CUSTOM_BG_COLOR;
    case 'Draggable Area_bgColor':
    case 'Button_bgColor':
      return CONSTANTS.DRAG_BG_COLOR;
    case 'Draggable Area_fontColor':
    case 'Button_fontColor':
      return CONSTANTS.CUSTOM_FONT_COLOR;
    default:
      return colorType === 'bgColor' ? CONSTANTS.BG_COLOR : CONSTANTS.FONT_COLOR;
  }
};

const ColorPickerComponent = (props: ColorPickerComponentInterface) => {
  const { color, SetColor, title, type } = props;

  const changeFontColorHandler = (color?: string) => {
    let value: {} = {};
    switch (type) {
      case 'fontColor':
      case 'Draggable Area_fontColor':
      case 'Button_fontColor':
        value = { fontColor: color ?? getDefaultColor(type, 'fontColor') };
        break;
      case 'bgColor':
      case 'click_bgColor':
      case 'reveal_bgColor':
      case 'frontcard_bgColor':
      case 'backcard_bgColor':
      case 'Draggable Area_bgColor':
      case 'Button_bgColor':
        value = { bgcolor: color ?? getDefaultColor(type, 'bgColor') };
        break;
      case 'borderColor':
        value = { borderColor: color ?? CONSTANTS.BORDER_COLOR };
        break;
      case 'btnFontColor':
        value = { fontColor: color ?? CONSTANTS.BTN_FONT_COLOR };
        break;
      case 'btnbgColor':
        value = { color: color ?? CONSTANTS.BTN_BG_COLOR };
        break;
      case 'separatorcolor':
        value = { separatorColor: color ?? CONSTANTS.SEPERATOR_COLOR };
        break;
      case 'inActiveBgColor':
        value = { displayActiveColorPicker: color ?? CONSTANTS.INACTIVE_BG_COLOR };
        break;
      case 'inActivefontColor':
        value = { inactivefontColor: color ?? CONSTANTS.INACTIVE_FONT_COLOR };
        break;
      case 'questionBgColor':
        value = { bgColor: color ?? CONSTANTS.QUESTION_BG_COLOR };
        break;
      case 'optionBgColor':
        value = { opBgColor: color ?? CONSTANTS.OPTION_BG_COLOR };
        break;
      case 'pbFontColor':
        value = { pbFontColor: color ?? CONSTANTS.PB_FONT_COLOR };
        break;
      case 'pbBgColor':
        value = { pbBgColor: color ?? CONSTANTS.PB_BG_COLOR };
        break;
      case 'buttonFontColor':
        value = { buttonFontColor: color ?? CONSTANTS.MCQ_BTN_FONT_COLOR };
        break;
      case 'buttonBgColor':
        value = { buttonBgColor: color ?? CONSTANTS.MCQ_BTN_BG_COLOR };
        break;
      case 'iconcolor':
        value = { iconColor: color ?? CONSTANTS.ICON_COLOR };
        break;
      case 'dropzoneBgColor':
        value = { dropzoneBgColor: color ?? CONSTANTS.DROPZONE_BG_COLOR };
        break;
      case 'imageBgColor':
        value = { imageBgColor: color ?? CONSTANTS.IMAGE_BG_COLOR };
        break;
    }
    SetColor(value);
  };
  const handleInput = (className: string) => {
    const colorInput = getElementsByClassName(className)[0] as HTMLInputElement;
    colorInput.addEventListener('input', () => {
      const newColorValue = colorInput.value;
      const isValid =
        /^#[a-fA-F0-9]*$/.test(newColorValue) && ![2, 3, 6, 8].includes(newColorValue.length);
      changeFontColorHandler(newColorValue);
      if (isValid) {
        colorInput.classList.remove('invalid-color');
      }
      if (!isValid || !newColorValue.startsWith('#')) {
        colorInput.classList.add('invalid-color');
      }
    });
  };

  const colorPickerOnClickHandler = (type: string) => {
    const colorPickerEl = document.getElementById(`${type}-color-picker`) as HTMLElement;
    if (!colorPickerEl) return;
    ShowColorPicker(
      colorPickerEl,
      colorPickerEl.style.backgroundColor,
      {
        onClear: () => {
          changeFontColorHandler();
          return;
        },
        onChange: (color: string) => {
          changeFontColorHandler(color);
        },
        onClose: () => {
          return;
        },
      },
      null,
    );
  };
  return (
    <Form.Group
      className='colorpicker-wrapper color-picker-design-wrap'
      data-testid={`${type}-colorPicker`}
    >
      <Form.Label className='design-section-sub-text m-0'>{title}</Form.Label>
      <div className='colorpicker-container'>
        <input
          className={`${type}-input`}
          value={props?.color}
          maxLength={9}
          onChange={() => handleInput(`${type}-input`)}
        />
        <Button
          id={`${type}-color-picker`}
          className='color-picker'
          data-testid={`${type}-colorPicker-button`}
          onClick={() => colorPickerOnClickHandler(type)}
          style={{
            backgroundColor: color,
          }}
        />
      </div>
    </Form.Group>
  );
};

export default ColorPickerComponent;
