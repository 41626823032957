import React, { useState } from 'react';
import ModalComponent from '../../utils/modal';
import ImageModalBodyContent from './images';
import MediaPreview from './mediaPreview';
import { connect } from 'react-redux';
import { validateExternalLink } from '../../../middleware/utils';

interface MediaModalInterface {
  handleAddElement: Function;
  setShow: Function;
  mediaType: string;
  imageCollection: Array<{ id: number; name: string; url: string }>;
  setDecorativeText: Function;
  setDescription?: Function;
  decorativeText: string;
  show: boolean;
  imageType: string;
  handleLocalImageUpload: {
    setLocalImageDetails: Function;
    uploadImage: Function;
  };
  isErrorInFetch: boolean;
  isFetching: boolean;
  componentType?: string;
}

interface MediaModalBodyContentInterface {
  handleConfirmState: Function;
  screenType: string;
  mediaType: string;
  imageCollection: Array<{ id: number; name: string; url: string }>;
  selectedMediaDetails: {
    elementType: string;
    url: string;
    fileName: string;
    name: string;
    mediaType: string;
  };
  setSelectedMediaDetails: Function;
  isError: boolean;
  setIsError: Function;
  setDecorativeText: Function;
  setDescription: Function;
  setScreenType: Function;
  imageType: string;
  setLocalImageDetails: Function;
  isErrorInFetch: boolean;
  isFetching: boolean;
  fileNameDetails: { type?: string; isError?: boolean };
  setFileNameDetails: Function;
  componentType?: string;
}

const MediaModal = (props: MediaModalInterface) => {
  const {
    handleAddElement,
    setShow,
    mediaType,
    imageCollection,
    setDecorativeText,
    setDescription,
    show,
    imageType,
    handleLocalImageUpload,
    isErrorInFetch,
    isFetching,
    decorativeText,
  } = props;
  const { setLocalImageDetails, uploadImage } = handleLocalImageUpload;
  const [confirmButtonState, setConfirmButtonState] = useState(true);
  const [screenType, setScreenType] = useState('media');
  const [selectedMediaDetails, setSelectedMediaDetails] = useState({
    elementType: '',
    url: '',
    fileName: '',
    name: '',
    mediaType: '',
  });
  const [isError, setIsError] = useState(false);
  const [fileNameDetails, setFileNameDetails] = useState<{ type?: string; isError?: boolean }>({
    type: '',
    isError: false,
  });

  const getModalBodyContent = () => {
    return (
      <MediaModalBodyContent
        handleConfirmState={setConfirmButtonState}
        mediaType={mediaType}
        screenType={screenType}
        imageCollection={imageCollection}
        selectedMediaDetails={selectedMediaDetails}
        setSelectedMediaDetails={setSelectedMediaDetails}
        isError={isError}
        setIsError={setIsError}
        setDecorativeText={setDecorativeText}
        setDescription={setDescription as Function}
        setScreenType={setScreenType}
        setLocalImageDetails={setLocalImageDetails}
        imageType={imageType}
        isErrorInFetch={isErrorInFetch}
        isFetching={isFetching}
        fileNameDetails={fileNameDetails}
        setFileNameDetails={setFileNameDetails}
        componentType={props.componentType}
      />
    );
  };

  const handleConfirm = () => {
    const { elementType, url, fileName, name } = selectedMediaDetails;
    setConfirmButtonState(true);
    const finalUrl =
      elementType === 'externalLink'
        ? validateExternalLink(url)
        : elementType === 'icon'
        ? name
        : url;
    if (screenType === 'media' && (imageType === '' || elementType === 'video')) {
      if ((finalUrl as { error: string }).error) {
        setIsError(true);
        return;
      } else {
        setScreenType('preview');
      }
    } else {
      if (elementType === 'image' && checkFileName()) return;
      setScreenType('media');
      setShow('', 'close');
      imageType === 'localImage'
        ? uploadImage(fileName.trim())
        : handleAddElement(finalUrl, elementType);
      setSelectedMediaDetails({ elementType: '', url: '', fileName: '', name: '', mediaType: '' });
      setFileErrorDetails('', false);
      setLocalImageDetails('', {});
    }
  };

  const checkFileName = () => {
    const { fileName, elementType } = selectedMediaDetails;
    const isFileNameExits =
      imageType === 'localImage' &&
      imageCollection.some((element) => element.name === fileName.trim());
    const isFileNameMatching = decorativeText.trim() === fileName.trim();

    if (elementType === 'externalLink') {
      return false;
    } else if (isFileNameExits) {
      setFileErrorDetails('alreadyExist', true);
      return true;
    } else if (isFileNameMatching) {
      setFileErrorDetails('fileNameMatching', true);
      return true;
    }
    return false;
  };

  const setFileErrorDetails = (type: string, status: boolean) => {
    setFileNameDetails({
      type: type,
      isError: status,
    });
  };

  const handleAction = (type: string) => {
    if (type === 'cancel') {
      setShow('', 'close');
    }
    setLocalImageDetails('', {});
    setConfirmButtonState(true);
    setIsError(false);
    setScreenType('media');
    setSelectedMediaDetails({ elementType: '', url: '', fileName: '', name: '', mediaType: '' });
    setFileErrorDetails('', false);
  };

  return (
    <ModalComponent
      show={show}
      size='xl'
      handleCancel={() => handleAction('cancel')}
      modalDetails={{
        title: mediaType,
        body: getModalBodyContent(),
      }}
      confirmButton={{
        label: screenType === 'media' ? 'Next' : 'Add',
        disabled: confirmButtonState,
        handleConfirm: handleConfirm,
      }}
      cancelButton={{
        label: 'Cancel',
      }}
      backButton={{
        label: 'Previous',
        disabled: screenType === 'media',
        handleBack: () => handleAction('back'),
      }}
    />
  );
};

const MediaModalBodyContent = (props: MediaModalBodyContentInterface) => {
  const {
    handleConfirmState,
    screenType,
    mediaType,
    imageCollection,
    selectedMediaDetails,
    setSelectedMediaDetails,
    isError,
    setIsError,
    setDecorativeText,
    setDescription,
    setScreenType,
    setLocalImageDetails,
    imageType,
    isErrorInFetch,
    isFetching,
    fileNameDetails,
    setFileNameDetails,
  } = props;

  return (
    <>
      {screenType === 'media' && (
        <>
          {mediaType === 'Media' && (
            <ImageModalBodyContent
              handleConfirmState={handleConfirmState}
              handleSelectedElement={setSelectedMediaDetails}
              imageCollection={imageCollection}
              isError={isError}
              setIsError={setIsError}
              setScreenType={setScreenType}
              setLocalImageDetails={setLocalImageDetails}
              isErrorInFetch={isErrorInFetch}
              isFetching={isFetching}
              componentType={props.componentType}
            />
          )}
        </>
      )}
      {screenType === 'preview' && (
        <MediaPreview
          selectedMediaDetails={selectedMediaDetails}
          mediaType={mediaType}
          handleConfirmState={handleConfirmState}
          setDecorativeText={setDecorativeText}
          setDescription={setDescription}
          imageType={imageType}
          handleSelectedElement={setSelectedMediaDetails}
          fileNameDetails={fileNameDetails}
          setFileNameDetails={setFileNameDetails}
        />
      )}
    </>
  );
};

const mapStateToProps = (storage: {
  imageStorage: { imageCollection: Array<{ id: number; name: string; url: string }> };
}) => ({
  imageCollection: storage.imageStorage.imageCollection,
});

export default connect(mapStateToProps)(MediaModal);
