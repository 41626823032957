/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import ModalComponent from '../../utils/modal';
import ImageViewComponent from './imageView';
import { FormInterface } from '../../../redux/interface';
import { setParameter } from '../../../redux/form/form.actions';
import { validateDropZone } from '../validate';
import { LoreeInteractiveEditorDashboardContentType } from '../../editor/editorUtilityFunctions/lintEditorType';
import { ReactComponent as HotspotIcon } from '../../../assets/icons/hotspotEditIcon.svg';

interface AddAreaComponentInterFace {
  type: string;
  index: number;
  panel: Array<string>;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const mapState = ({ form }: any) => ({
  dndParameters: form.dndParameters,
  fontSize: form.fontSize,
  errors: form.errors,
});

const mapDispatch = (dispatch: Function) => ({
  HandleParameters: (form: FormInterface) => dispatch(setParameter(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & AddAreaComponentInterFace;

const AddAreaComponent = (props: Props) => {
  const { type, index, dndParameters, fontSize, HandleParameters, errors, panel, editorConfig } =
    props;
  const [screenType, setScreenType] = useState('view');
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState('');
  const [text, setText] = useState('');
  const [parameters, setParameters] = useState({ ...dndParameters[index] });
  const [actionHandlerState, setActionHandlerState] = useState(true);
  const [activeZone, setActiveZone] = useState('');
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 });
  const [error, setError] = useState({});
  const [propId, setPropId] = useState('');

  useEffect(() => {
    setParameters({ ...dndParameters[index] });
  }, [dndParameters, index]);

  const addDropZone = () => {
    const width =
      imageDimension && imageDimension?.width >= 150 ? '150px' : `${imageDimension?.width}px`;
    const height =
      imageDimension && imageDimension?.height >= 150 ? '150px' : `${imageDimension?.height}px`;
    const id = propId !== '' ? propId : parameters.dropZone.length;
    const randomCoordinates = Math.floor(Math.random() * 200) + 1;

    const errors = validateDropZone(parameters, label, text, parseInt(activeZone), false);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      return;
    }

    parameters.dropZone = [
      ...parameters.dropZone,
      {
        id: id,
        data: {
          x: randomCoordinates,
          y: randomCoordinates,
          label: label,
          text: text,
          width: width,
          height: height,
          imgWidth: imageDimension?.width,
          imgHeight: imageDimension?.height,
        },
      },
    ];
    switchScreen();
    setPropId('');
    setParameters(parameters);
    setActiveZone(id);
    setActionHandlerState(false);
  };

  const handleDropZoneAction = () => {
    const elementsIndex = parameters.dropZone.findIndex(
      (element: { id: number }) => element.id === parseInt(activeZone),
    );
    if (elementsIndex === -1) return;
    const dropZoneData = parameters.dropZone.map((a: {}) => ({ ...a }));
    if (screenType === 'editDetails') {
      const errors = validateDropZone(parameters, label, text, parseInt(activeZone), true);
      if (Object.keys(errors).length !== 0) {
        setError(errors);
        return;
      }
      dropZoneData[elementsIndex].data = {
        ...dropZoneData[elementsIndex].data,
        label: label,
        text: text,
      };
      switchScreen();
    } else {
      dropZoneData.splice(elementsIndex, 1);
      setPropId(activeZone);
      setActiveZone('');
      setActionHandlerState(true);
    }
    parameters.dropZone = [...dropZoneData];
    setParameters({ ...parameters });
  };

  const switchScreen = () => {
    setError({});
    setLabel('');
    setText('');
    setScreenType('view');
  };

  const setDimension = (obj: any, idx: number) => {
    const elementsIndex = parameters.dropZone.findIndex((element: any) => element.id === idx);
    let dimensions = document.getElementById('dndImage')?.getBoundingClientRect();
    const data = parameters.dropZone.map((a: {}) => ({ ...a }));
    data[elementsIndex].data = {
      ...data[elementsIndex].data,
      x: obj.x,
      y: obj.y,
      width: obj.w,
      height: obj.h,
      imgWidth: dimensions?.width,
      imgHeight: dimensions?.height,
    };
    parameters.dropZone = data;
    setParameters({ ...parameters });
  };

  const handleSave = () => {
    const content = [...dndParameters];
    content[index] = { ...parameters };
    let errorObject = { ...errors };
    delete errorObject[`${panel[1]}`];
    HandleParameters({
      dndParameters: content,
      errors: errorObject,
    });
    handleModalClose();
  };

  const setHandleConfirm = () => {
    switch (screenType) {
      case 'details':
        return addDropZone;
      case 'editDetails':
        return handleDropZoneAction;
      default:
        return handleSave;
    }
  };

  const handleModalClose = () => {
    setShow(false);
    setParameters({ ...dndParameters[index] });
    switchScreen();
    setActionHandlerState(true);
    setActiveZone('');
  };

  const getTitle = () => {
    const isDnd = type === 'DragandDrop';
    switch (screenType) {
      case 'editDetails':
        return `Edit ${isDnd ? 'Drop Zone' : 'Hotspot'}`;
      case 'details':
        return `Add ${isDnd ? 'Drop Zone' : 'Hotspot'}`;
      default:
        return `Add or Edit ${isDnd ? 'Drop Zones' : 'Hotspots'}`;
    }
  };

  return (
    <>
      <div className='text-center mt-2'>
        <Button className='footer-cancel-btn btn btn-primary' onClick={() => setShow(true)}>
          Add/Edit {type === 'DragandDrop' ? 'Drop Zones' : 'Hotspots'}
        </Button>
      </div>
      <ModalComponent
        dialogClassName='modal-90w'
        show={show}
        handleCancel={handleModalClose}
        modalDetails={{
          title: (
            <div
              style={{
                height: '20px',
                fontFamily: 'Lexend',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#585858',
              }}
            >
              {getTitle().match(/Hotspot/i) ? (
                <>
                  <HotspotIcon style={{ marginRight: '10px' }} />
                  {getTitle()}
                </>
              ) : (
                getTitle()
              )}{' '}
            </div>
          ),
          body: (
            <ImageViewComponent
              type={type}
              parameters={parameters}
              fontSize={fontSize}
              screenType={screenType}
              setScreenType={(type: string) => setScreenType(type)}
              label={{ value: label, setLabel: setLabel }}
              text={{ value: text, setText: setText }}
              setDimension={setDimension}
              icons={{
                status: actionHandlerState,
                setStatus: setActionHandlerState,
              }}
              dropZone={{
                activeIndex: parseInt(activeZone),
                setActiveIndex: setActiveZone,
              }}
              handleEdit={() => {
                const elementsIndex = parameters.dropZone.findIndex(
                  (element: { id: number }) => element.id === parseInt(activeZone),
                );
                setLabel(parameters.dropZone[elementsIndex].data.label);
                setText(parameters.dropZone[elementsIndex].data.text);
                setScreenType('editDetails');
              }}
              handleDelete={handleDropZoneAction}
              setImageDimension={setImageDimension}
              errorHandler={{ error: error, setError: setError }}
              editorConfig={editorConfig}
            />
          ),
        }}
        confirmButton={{
          label: screenType === 'view' ? 'Save' : screenType === 'details' ? 'Add' : 'Update',
          disabled: false,
          handleConfirm: setHandleConfirm(),
        }}
        cancelButton={{
          label: 'Cancel',
          handleCancel: screenType === 'view' ? handleModalClose : switchScreen,
        }}
      />
    </>
  );
};

export default connector(AddAreaComponent);
