/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defaultValue } from '../../middleware/defaultValue';
import './customize.scss';
import {
  fontHeadings,
  fontType,
  fontSize,
  fontWeight,
  radius,
  setIcon,
  setBgColor,
  fontColor,
  inactivefontColor,
  setAlignment,
  setBorderSize,
  setBorderColor,
  setBorderStyle,
  errorsFetch,
  setIconColor,
  setIconAlignment,
  setBorderTop,
  setBorderBottom,
  setBorderRight,
  setBorderLeft,
  setBorderRadius,
  setMarginTop,
  setMarginBottom,
  setSeparatorWidth,
  setSeparatorColor,
  setdisplayInActiveColorPicker,
  setcaptionAlignment,
  setIconType,
  setIconSize,
  setImageBgColor,
  noOfLayout,
  fixedLayout,
} from '../../redux/form/form.actions';
import DropDownComponent from '../utils/dropDown';
import { customStyles } from '../customizeComponentLayout/styles';
import AlignmentComponent from '../customizeComponentLayout/alignment';
import MarginComponent from '../customizeComponentLayout/margin';
import ColorPickerComponent from '../customizeComponentLayout/colorPicker';
import { ReactComponent as AccordionExpand } from '../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../assets/icons/accordionHide.svg';
import FontHandlerComponent from '../customizeComponentLayout/fontHandler';
import { FormInterface } from '../../redux/interface';
import BorderCustomizeComponent from '../customizeComponentLayout/borderCustomize';
import IconAlignmentComponent from '../customizeComponentLayout/iconAlignment';
import { isFontNameAvailable } from './utils';

const ComponentCustomize = (props: any) => {
  const [openAccordion, setOpenAccordion] = useState<string>('sectionHeader');
  const [isRendered, setIsRendered] = useState(false);

  const toggleCollapse = (accordionId: string) => {
    setOpenAccordion((prevAccordion) => (prevAccordion === accordionId ? '' : accordionId));
  };

  const {
    fontHeadings,
    fontSize,
    borderColor,
    bgcolor,
    fontColor,
    inactivefontColor,
    borderStyle,
    icon,
    fontFamily,
    fontWeight,
    alignment,
    HandleFontHeadings,
    HandleFontWeight,
    HandleFontSize,
    HandleFontFamily,
    SetSeparatorWidth,
    separatorColor,
    displayActiveColorPicker,
    iconAlignment,
    fontFamilyPropsList,
    customHeaderStylesPropsList,
  } = props;

  useEffect(() => {
    const { HandleFontSize, HandleFontFamily, customHeaderStylesPropsList, fontFamilyPropsList } =
      props;
    const id = props.content.match.params.id;
    if (props.fontHeadings === '' && !isRendered && !id) {
      HandleFontSize({
        fontSize: parseInt(customHeaderStylesPropsList[6]?.paragraph?.size, 10).toString(),
      });
      HandleFontFamily({
        fontFamily: isFontNameAvailable(
          customHeaderStylesPropsList[6]?.paragraph?.font,
          fontFamilyPropsList,
        ),
      });
    }
    if (id !== undefined && !isRendered) {
      defaultValue(props);
    }
    setIsRendered(true);
  }, [props, isRendered]);

  const fontHeadingsHandler = (element: string | null) => {
    if (element !== null) {
      HandleFontHeadings({
        fontHeadings: element,
      });

      HandleFontWeight({
        fontWeight: 'Bold',
      });
      switch (element) {
        case 'h1':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[0].h1.size, 10),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[0].h1.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h2':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[1].h2.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[1].h2.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h3':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[2].h3.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[2].h3.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h4':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[3].h4.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[3].h4.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h5':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[4].h5.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[4].h5.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h6':
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[5].h6.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[5].h6.font,
              fontFamilyPropsList,
            ),
          });
          break;
        default:
          HandleFontWeight({
            fontWeight: 'Normal',
          });
          HandleFontSize({
            fontSize: parseInt(customHeaderStylesPropsList[6].paragraph.size, 10).toString(),
          });
          HandleFontFamily({
            fontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[6].paragraph.font,
              fontFamilyPropsList,
            ),
          });
          break;
      }
    }
  };

  const setIconHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { Icon } = props;
    Icon({
      icon: event.target.value,
    });
  };
  const setcaptionAlignmentHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { SetcaptionAlignment } = props;
    SetcaptionAlignment({
      setcaptionAlignment: event.target.value,
    });
  };

  const IconTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { IconType } = props;
    IconType({
      iconType: event.target.value,
    });
  };

  const iconSizeDisplay = () => {
    switch (props.iconSize) {
      case '10px':
        return 'Default';
      case '16px':
        return 'Medium';
      case '24px':
        return 'Large';
      default:
        return '';
    }
  };

  return (
    <Accordion className='mx-2 designsec-accordion' defaultActiveKey='sectionHeader'>
      <Accordion.Item eventKey='sectionHeader' className='border-0 my-3'>
        <Accordion.Header
          as='div'
          className={`custom-header-title m-0 ${
            openAccordion === 'sectionHeader' && 'selected-section'
          }`}
          onClick={() => toggleCollapse('sectionHeader')}
        >
          {openAccordion === 'sectionHeader' ? <AccordionExpand /> : <AccordionHide />}
          <div
            className={`px-2 ${
              openAccordion === 'sectionHeader' && 'selected-section'
            } section-header-title`}
          >
            {props.contentType === 'imageslider' ? 'Caption' : 'Section Header'}
          </div>
        </Accordion.Header>
        <Accordion.Body className='p-0 pt-3'>
          <FontHandlerComponent
            componentType='Font Family'
            setFontProperty={props.HandleFontFamily}
            fontPropertyValue={fontFamily}
            options={customStyles.fontFamily}
            customFonts={fontFamilyPropsList}
          />
          <Form.Group>
            <Form.Label className='design-section-sub-text'>Font Style</Form.Label>
            <DropDownComponent
              setSelectedOption={fontHeadingsHandler}
              selectedOption={fontHeadings ? fontHeadings : ''}
              className='lint-customize-dropdown nofont-family'
              optionCollection={customStyles.fontHeadings}
            />
          </Form.Group>
          <FontHandlerComponent
            componentType='Font Size'
            setFontProperty={props.HandleFontSize}
            fontPropertyValue={fontSize}
            options={customStyles.fontSize}
          />
          <FontHandlerComponent
            componentType='Font Weight'
            setFontProperty={props.HandleFontWeight}
            fontPropertyValue={fontWeight}
            options={customStyles.fontWeight}
          />
          {props.contentType !== 'imageslider' && (
            <AlignmentComponent alignmentValue={alignment} SetAlignmentValue={props.Setalignment} />
          )}
          {props.contentType === 'Accordion' && (
            <MarginComponent
              top={props.marginTop}
              bottom={props.marginBottom}
              SetTop={props.SetMarginTop}
              SetBottom={props.SetMarginBottom}
            />
          )}
          <ColorPickerComponent
            type='fontColor'
            title={props.contentType === 'Tabs' ? 'Active tab text color' : 'Font Color'}
            SetColor={props.FontColor}
            color={fontColor}
          />
          {props.contentType !== 'Tabs' && (
            <ColorPickerComponent
              type='bgColor'
              title='Background Color'
              SetColor={props.BgColor}
              color={bgcolor}
            />
          )}

          {props.contentType === 'imageslider' && (
            <div>
              <span className='design-section-text'>Caption Position</span>
              <div>
                <Form.Check
                  inline
                  label='Top'
                  name='captionAlignment'
                  type='radio'
                  value='top'
                  id='Top-button'
                  onChange={setcaptionAlignmentHandler}
                  checked={props.setcaptionAlignment === 'top'}
                  className='icon-label'
                />
                <Form.Check
                  inline
                  label='Bottom'
                  name='captionAlignment'
                  type='radio'
                  value='bottom'
                  id='bottom-button'
                  onChange={setcaptionAlignmentHandler}
                  checked={props.setcaptionAlignment === 'bottom'}
                  className='icon-label'
                />
              </div>
            </div>
          )}
          {props.contentType === 'Tabs' && (
            <>
              <ColorPickerComponent
                type='inActiveBgColor'
                title={
                  props.contentType === 'Tabs' ? 'Active tab background color' : 'Background Color'
                }
                SetColor={props.DisplayInActiveColorPicker}
                color={displayActiveColorPicker}
              />
              <ColorPickerComponent
                type='inActivefontColor'
                title='In-active tab text color'
                SetColor={props.InactiveFontColor}
                color={inactivefontColor}
              />
              <ColorPickerComponent
                type='bgColor'
                title='In-active tab background color'
                SetColor={props.BgColor}
                color={bgcolor}
              />
              <ColorPickerComponent
                type='separatorcolor'
                title='Separator color'
                SetColor={props.SeparatorColor}
                color={separatorColor}
              />
              <FontHandlerComponent
                componentType='Separator size'
                setFontProperty={SetSeparatorWidth}
                fontPropertyValue={props.separatorWidth}
                options={customStyles.separatorSize}
              />
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
      {props.contentType !== 'imageslider' && (
        <Accordion.Item eventKey='border' className='border-0 my-3'>
          <Accordion.Header
            as='div'
            className={`custom-header-title m-0 ${
              openAccordion === 'border' && 'selected-section'
            }`}
            onClick={() => toggleCollapse('border')}
          >
            {openAccordion === 'border' ? <AccordionExpand /> : <AccordionHide />}
            <div
              className={`px-2 ${
                openAccordion === 'border' && 'selected-section'
              } section-header-title border-title`}
            >
              Border
            </div>
          </Accordion.Header>
          <Accordion.Body className='p-0 pt-3'>
            <BorderCustomizeComponent
              borderLeft={props.borderLeft}
              borderTop={props.borderTop}
              borderRight={props.borderRight}
              borderBottom={props.borderBottom}
              SetBorderLeft={props.SetBorderLeft}
              SetBorderRight={props.SetBorderRight}
              SetBorderBottom={props.SetBorderBottom}
              SetBorderTop={props.SetBorderTop}
              borderStyle={borderStyle}
              SetBorderStyle={props.HandleBorderStyle}
              borderRadius={props.borderRadius}
              SetBorderRadius={props.SetBorderRadius}
              borderColor={borderColor}
              SetBorderColor={props.BorderColor}
            />
          </Accordion.Body>
        </Accordion.Item>
      )}
      {(props.contentType === 'Accordion' || props.contentType === 'imageslider') && (
        <Accordion.Item eventKey='icon' className='border-0 my-3'>
          <Accordion.Header
            as='div'
            className={`custom-header-title m-0 ${openAccordion === 'icon' && 'selected-section'}`}
            onClick={() => toggleCollapse('icon')}
          >
            {openAccordion === 'icon' ? <AccordionExpand /> : <AccordionHide />}
            <div
              className={`px-2 ${
                openAccordion === 'icon' && 'selected-section'
              } section-header-title icon-title`}
            >
              Icon
            </div>
          </Accordion.Header>
          <Accordion.Body className='pb-0 px-2 pt-3'>
            {props.contentType === 'imageslider' && (
              <>
                <ColorPickerComponent
                  type='iconcolor'
                  title='Icon Color'
                  SetColor={props.SetIconColor}
                  color={props.iconColor}
                />
                <FontHandlerComponent
                  componentType='Icon Size'
                  setFontProperty={props.SetIconSize}
                  fontPropertyValue={iconSizeDisplay()}
                  options={customStyles.iconSize}
                />
                <IconAlignmentComponent
                  alignmentValue={iconAlignment}
                  SetAlignmentValue={props.SetIconAlignment}
                />
                <div className='icon-items container-inner'>
                  <span className='design-section-text'>Icon Type</span>
                  <div>
                    <Form.Check
                      inline
                      label='Square'
                      name='eventType'
                      type='radio'
                      value='0%'
                      id='square-button'
                      onChange={IconTypeHandler}
                      checked={props.iconType === '0%'}
                      className='icon-label'
                    />
                    <Form.Check
                      inline
                      label='Circle'
                      name='eventType'
                      type='radio'
                      value='50%'
                      id='round-button'
                      onChange={IconTypeHandler}
                      checked={props.iconType === '50%'}
                      className='icon-label'
                    />
                  </div>
                </div>
              </>
            )}
            {props.contentType !== 'imageslider' && (
              <div className='icon-items container-inner'>
                <span className='design-section-sub-text'>Type</span>
                <div className='icons-container'>
                  <Form.Check
                    inline
                    label='Arrow'
                    type='radio'
                    name='icon'
                    value='arrow'
                    id='arrow-button'
                    onChange={setIconHandler}
                    checked={icon === 'arrow'}
                    className='icon-label m-0'
                  />
                  <Form.Check
                    inline
                    label='Plus/Minus'
                    type='radio'
                    name='icon'
                    value='plus/minus'
                    id='plusminus-button'
                    onChange={setIconHandler}
                    checked={icon === 'plus/minus'}
                    className='icon-label m-0'
                  />
                </div>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      )}
      {props.contentType === 'imageslider' && (
        <Accordion.Item eventKey='imageBg' className='border-0 my-3'>
          <Accordion.Header
            as='div'
            className={`custom-header-title m-0 ${
              openAccordion === 'imageBg' && 'selected-section'
            }`}
            onClick={() => toggleCollapse('imageBg')}
          >
            {openAccordion === 'imageBg' ? <AccordionExpand /> : <AccordionHide />}
            <div
              className={`px-2 ${
                openAccordion === 'imageBg' && 'selected-section'
              } section-header-title`}
            >
              Image/Video Background
            </div>
          </Accordion.Header>
          <Accordion.Body className='pb-0 px-2 pt-3'>
            <ColorPickerComponent
              type='imageBgColor'
              title='Background Color'
              SetColor={props.setImageBgColor}
              color={props.imageBgColor}
            />
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
};

//state from redux
const mapStateToProps = ({ form }: any) => ({
  type: form.type,
  fontHeadings: form.fontHeadings,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  radius: form.radius,
  icon: form.icon,
  color: form.color,
  bgcolor: form.bgcolor,
  alignment: form.alignment,
  fontColor: form.fontColor,
  inactivefontColor: form.inactivefontColor,
  borderType: form.borderType,
  borderColor: form.borderColor,
  borderSize: form.borderSize,
  borderStyle: form.borderStyle,
  setcaptionAlignment: form.setcaptionAlignment,
  errors: form.errors,
  iconColor: form.iconColor,
  iconSize: form.iconSize,
  iconAlignment: form.iconAlignment,
  iconType: form.iconType,
  imageBgColor: form.imageBgColor,
  borderTop: form.borderTop,
  borderRight: form.borderRight,
  borderLeft: form.borderLeft,
  borderBottom: form.borderBottom,
  borderRadius: form.borderRadius,
  marginBottom: form.marginBottom,
  marginTop: form.marginTop,
  displayActiveColorPicker: form.displayActiveColorPicker,
  separatorColor: form.separatorColor,
  separatorWidth: form.separatorWidth,
  noOfLayout: form.noOfLayout,
  fixedLayout: form.fixedLayout,
});

//props to redux
const mapDispatchToProps = (dispatch: any) => ({
  HandleFontHeadings: (form: any) => dispatch(fontHeadings(form)),
  HandleFontFamily: (form: any) => dispatch(fontType(form)),
  HandleFontSize: (form: any) => dispatch(fontSize(form)),
  HandleFontWeight: (form: any) => dispatch(fontWeight(form)),
  Radius: (form: any) => dispatch(radius(form)),
  Icon: (form: any) => dispatch(setIcon(form)),
  BgColor: (form: any) => dispatch(setBgColor(form)),
  Setalignment: (form: any) => dispatch(setAlignment(form)),
  FontColor: (form: any) => dispatch(fontColor(form)),
  InactiveFontColor: (form: FormInterface) => dispatch(inactivefontColor(form)),
  BorderColor: (form: any) => dispatch(setBorderColor(form)),
  HandleBorderSize: (form: any) => dispatch(setBorderSize(form)),
  HandleBorderStyle: (form: any) => dispatch(setBorderStyle(form)),
  ErrorsFetch: (data: any) => dispatch(errorsFetch(data)),
  IconColor: (form: any) => dispatch(setIconColor(form)),
  iconalignment: (form: any) => dispatch(setIconAlignment(form)),
  SetBorderTop: (form: FormInterface) => dispatch(setBorderTop(form)),
  SetBorderBottom: (form: FormInterface) => dispatch(setBorderBottom(form)),
  SetBorderRight: (form: FormInterface) => dispatch(setBorderRight(form)),
  SetBorderLeft: (form: FormInterface) => dispatch(setBorderLeft(form)),
  SetBorderRadius: (form: FormInterface) => dispatch(setBorderRadius(form)),
  SetMarginTop: (form: FormInterface) => dispatch(setMarginTop(form)),
  SetMarginBottom: (form: FormInterface) => dispatch(setMarginBottom(form)),
  SetSeparatorWidth: (form: FormInterface) => dispatch(setSeparatorWidth(form)),
  SeparatorColor: (form: FormInterface) => dispatch(setSeparatorColor(form)),
  SetcaptionAlignment: (form: FormInterface) => dispatch(setcaptionAlignment(form)),
  IconType: (form: FormInterface) => dispatch(setIconType(form)),
  SetIconSize: (form: FormInterface) => dispatch(setIconSize(form)),
  SetIconAlignment: (form: FormInterface) => dispatch(setIconAlignment(form)),
  SetIconColor: (form: FormInterface) => dispatch(setIconColor(form)),
  setImageBgColor: (form: FormInterface) => dispatch(setImageBgColor(form)),
  DisplayInActiveColorPicker: (form: FormInterface) =>
    dispatch(setdisplayInActiveColorPicker(form)),
  noOfLayouts: (form: FormInterface) => dispatch(noOfLayout(form)),
  fixedLayouts: (form: FormInterface) => dispatch(fixedLayout(form)),
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(ComponentCustomize);
