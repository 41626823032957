/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ToastManager } from '@crystaldelta/loree-ui-components';
import ReactHtmlParser from 'react-html-parser';
import MediaModal from '../editor/mediaManagement/mediaModal';
import { fetchImages, uploadToS3 } from '../../middleware/api';
import { setImageCollection } from '../../redux/mediaManagement/images.action';
import { ReactComponent as AddIcon } from '../../assets/icons/Add.svg';
import { fontSize } from '../../redux/form/form.actions';

interface ImageFormBodyComponentProps {
  panel: Array<string>;
  index: number;
  errors: any;
  parameter: any;
  type: string;
  handleChange: Function;
  handleTitleChange: Function;
  SetImageCollection: Function;
  imageCollection: any;
}

const ImageFormBodyComponent = (props: ImageFormBodyComponentProps) => {
  const { panel, index, errors, parameter, type, handleChange, handleTitleChange } = props;
  const [showModal, setShowModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isErrorInFetch, setIsErrorInFetch] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageType, setImageType] = useState('');
  const [mediaType, setMediaType] = useState('Media');
  const [decorativeText, setDecorativeText] = useState('');
  const [description, setDescription] = useState('');
  const [imageFileDetails, setImageFileDetails] = useState<BlobPart>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [imageHovered, setImageHovered] = useState(false);

  const selectedLocalImageDetails = (type: string, file: Blob) => {
    setImageType(type);
    setImageFileDetails(file);
  };
  useEffect(() => {
    const renderImageCollections = async () => {
      if (props.imageCollection.length === 0) {
        await updateImageCollection();
      }
    };
    renderImageCollections();
  });
  const uploadLocalImage = (updatedFileName: string) => {
    setUploading(true);
    saveImageToS3(updatedFileName);
  };

  const saveImageToS3 = async (updatedFileName: string) => {
    try {
      const updatedFileDetatils = new File([imageFileDetails as Blob], `${updatedFileName}`, {
        type: (imageFileDetails as Blob)?.type,
      });
      const data = new FormData();
      data.append('image', updatedFileDetatils);
      const updatedFile = await uploadToS3(data);
      insertMediaToSection(updatedFile.url, mediaType);
      selectedLocalImageDetails('', {} as Blob);
      await updateImageCollection();
    } catch (error) {
      console.error('error in uploadingImages', error);
      setShowToast(true);
      setToastMessage('An error occured while uploading Images');
      setUploading(false);
    }
  };
  const updateImageCollection = async () => {
    try {
      setFetching(true);
      const { SetImageCollection } = props;
      const orgId = sessionStorage.getItem('org_id');
      const getImages = await fetchImages(orgId);
      SetImageCollection({ imageCollection: getImages });
      setFetching(false);
    } catch (error) {
      console.error('error occured while fetching images', error);
      setFetching(false);
      setIsErrorInFetch(true);
    }
  };

  const handleMediaSection = (value: string, altValue: string) => {
    let content = [...parameter];
    let errorObject = { ...errors };
    delete errorObject[`${panel[1]}`];
    delete errorObject[`alt_${panel[1]}`];
    delete errorObject[`title_${panel[1]}`];
    if (value === '') {
      content[index] = { ...content[index], text: '', alt: '' };
    } else {
      content[index] = { ...content[index], text: value, alt: altValue };
    }
    handleChange({
      parameters: content,
      errors: errorObject,
    });
  };

  const insertMediaToSection = (url: string, type: string) => {
    let altText;
    if (type === 'image' || type === 'Media' || type === 'externalLink') {
      altText = decorativeText;
    } else {
      altText = description;
    }
    let selectedMedia;
    switch (true) {
      case type === 'video':
        selectedMedia = `<iframe src='${url}' title='${altText}'/>`;
        break;
      default:
        selectedMedia = `<img src='${url}' alt='${altText}' role='${altText === '' ? 'presentation' : ''
          }'/>`;
        break;
    }
    handleMediaSection(selectedMedia, altText);
    setUploading(false);
  };

  const handleShowModal = (mediaType: string, status: string) => {
    setMediaType(mediaType);
    setShowModal(!showModal);
    if (status === 'close') return;
  };

  const getElementValue = () => {
    const imageTag = fetchElement();
    return {
      title:
        imageTag?.tagName === 'IMG'
          ? (imageTag as HTMLImageElement)?.alt
          : (imageTag as HTMLElement)?.title,
      src: (imageTag as HTMLImageElement)?.src,
      type: (imageTag as HTMLImageElement)?.tagName,
      element: imageTag?.outerHTML,
    };
  };

  const fetchElement = () => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = parameter[index]['text'];
    return wrapper?.children[0]?.tagName === 'IFRAME'
      ? wrapper?.getElementsByTagName('iframe')[0]
      : wrapper?.getElementsByTagName('img')[0];
  };

  const setAltValue = (altValue: string, eventType: string) => {
    let decorativeText = parameter[index].alt;
    decorativeText = decorativeText ? decorativeText : getElementValue().title;
    decorativeText = decorativeText === '' ? ' ' : decorativeText;
    let alt = eventType === 'change' ? altValue : decorativeText;
    if (eventType === 'click' && altValue === ' ') {
      alt = decorativeText;
    }
    const mediaElement = fetchElement();
    if (!mediaElement) {
      return;
    } else if (mediaElement.tagName === 'IFRAME') {
      mediaElement.title = eventType === 'click' && altValue === ' ' ? alt : altValue;
    } else {
      (mediaElement as HTMLImageElement).alt =
        eventType === 'click' && altValue === ' ' ? alt : altValue;
      mediaElement.role = altValue === '' ? 'presentation' : '';
    }
    handleMediaSection(`${mediaElement.outerHTML}`, alt);
  };

  return (
    <>
      <div>
        <Form.Group className='section-content'>
          <Form.Label className='title option_section' htmlFor={panel[1]}>
            {type === 'DragandDrop' ? 'Image*' : 'Image/Video*'}
          </Form.Label>
        </Form.Group>
        {parameter[index]['text'] === '' && (
          <>
            {uploading ? (
              <p className='text-center fw-bolder' style={{ color: '#112299' }}>
                Uploading...
              </p>
            ) : (
              <div
                className='pt-3 m-3 text-center imageSliderFormBody'
                tabIndex={0}
                role='button'
                onClick={() => {
                  handleShowModal('Media', 'open');
                }}
                onKeyDown={() => {
                  handleShowModal('Media', 'open');
                }}
              >
                <AddIcon />
                <p className='py-1'>
                  Click here to add a {type === 'DragandDrop' ? 'image' : 'media'}
                </p>
              </div>
            )}
          </>
        )}
        {parameter[index]['text'] !== '' && (
          <Row>
            <Col
              className='img-container'
              data-testid='img-container'
              md={4}
              onMouseEnter={() => setImageHovered(true)}
              onMouseLeave={() => setImageHovered(false)}
            >
              <div className='image-wrapper'>
                {ReactHtmlParser(getElementValue().element)}
                <div className={`overlay ${imageHovered ? 'visible' : ''}`} data-testid='overlay' />
              </div>
              {imageHovered && (
                <div className='btn-container'>
                  <button
                    className='remove-img-btn'
                    data-testid='remove-img-btn'
                    onClick={() => {
                      handleMediaSection('', '');
                    }}
                  >
                    Remove
                  </button>
                  <button
                    className='replace-img-btn'
                    data-testid='replace-img-btn'
                    onClick={() => {
                      handleShowModal('Media', 'open');
                    }}
                  >
                    Replace
                  </button>
                </div>
              )}
            </Col>
            <Col md={8}>
              {parameter[index]['text']?.includes('img') ? (
                <div className='d-flex flex-column'>
                  <Form.Label className='pb-1 mb-0' id='lint-media-modal-label-for-alt-text'>
                    Alternative text *
                  </Form.Label>
                  <textarea
                    id='lint-image-alt-input'
                    className='mx-1 w-100 right-container lint-image-editor-text-area'
                    placeholder='  Alternative text'
                    data-testid='alt-text'
                    value={
                      parameter[index].alt
                        ? parameter[index].alt === ' '
                          ? parameter[index].alt.trim()
                          : parameter[index].alt
                        : getElementValue().title.replace(/ +/g, ' ')
                    }
                    maxLength={150}
                    disabled={getElementValue().title === ''}
                    onChange={(event) =>
                      setAltValue(event.target.value === '' ? ' ' : event.target.value, 'change')
                    }
                  />
                  <Form.Group>
                    <Form.Check
                      checked={getElementValue().title === ''}
                      className='mx-1 my-2'
                      label='Decorative image'
                      id={`decorativeCheckbox-${index}`}
                      data-testid={`decorativeCheckbox-${index}`}
                      style={{
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: 350,
                        color: '#585858',
                      }}
                      onChange={(event) => {
                        setAltValue(event.target.checked ? '' : ' ', 'click');
                      }}
                    />
                  </Form.Group>
                  {errors && (
                    <Form.Text className='text-danger'>{errors[`alt_${panel[1]}`]}</Form.Text>
                  )}
                </div>
              ) : (
                <div className='d-flex flex-column'>
                  <label className='pb-1 mb-0' htmlFor='videoTitle'>
                    Title name*
                  </label>
                  <input
                    id='videoTitle'
                    type='text'
                    autoComplete='off'
                    className='p-1'
                    value={getElementValue()?.title?.replace(/ +/g, ' ')}
                    onChange={(event) => {
                      const value = event.target.value;
                      setDescription(value);
                      setAltValue(value === '' ? ' ' : value, 'change');
                    }}
                  />
                  {errors && (
                    <Form.Text className='text-danger'>{errors[`title_${panel[1]}`]}</Form.Text>
                  )}
                </div>
              )}
            </Col>
          </Row>
        )}
        {errors && <Form.Text className='text-danger'>{errors[`${panel[1]}`]}</Form.Text>}
        <Form.Group className='mb-0 pt-2 p-3'>
          <Form.Label className='title option_section'>
            {type === 'DragandDrop' ? 'Droppable Text*' : 'Caption*'}
          </Form.Label>
          <FormControl
            className='lint-custom-input '
            id={panel[0]}
            name={panel[0]}
            type='text'
            onChange={(event) => {
              handleTitleChange(index, event);
            }}
            value={parameter[index]['title']}
            autoComplete='off'
          />
        </Form.Group>
        {errors && <Form.Text className='text-danger'>{errors[`${panel[0]}`]}</Form.Text>}
      </div>
      <MediaModal
        show={showModal}
        setDecorativeText={(text: string) => {
          setDecorativeText(text);
        }}
        setDescription={(text: string) => {
          setDescription(text);
        }}
        decorativeText={decorativeText}
        isErrorInFetch={isErrorInFetch}
        isFetching={fetching}
        imageType={imageType}
        mediaType={mediaType}
        setShow={(mediaType: string, status: string) => handleShowModal(mediaType, status)}
        handleAddElement={(elementDetail: string, type: string) => {
          insertMediaToSection(elementDetail, type);
        }}
        handleLocalImageUpload={{
          setLocalImageDetails: (type: string, file: any) => selectedLocalImageDetails(type, file),
          uploadImage: (fileName: string) => uploadLocalImage(fileName),
        }}
        componentType={type}
      />
      {showToast && (
        <ToastManager
          toastType='error'
          toastMessage={toastMessage}
          closeButton
          closeToast={() => setShowToast(false)}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ imageStorage }: any) => ({
  imageCollection: imageStorage.imageCollection,
});
//props to redux
const mapDispatchToProps = (dispatch: Function) => ({
  SetImageCollection: (imageStorage: any) => dispatch(setImageCollection(imageStorage)),
});
//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(ImageFormBodyComponent);
