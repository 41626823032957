import React from 'react';
import './dropDown.scss';
import { getInteractiveTitle } from '../formComponent/utils';
import { CustomDropDown, CustomDropDownOption } from './customStyledComponents';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { ReactComponent as FilterIcon } from '../../assets/icons/filterIcon.svg';

interface DropDownComponentInterface {
  optionCollection: {};
  setSelectedOption: Function;
  selectedOption: string;
  className: string;
  type?: string;
}

const DropDownComponent = (props: DropDownComponentInterface) => {
  const { optionCollection, setSelectedOption, selectedOption, className, type } = props;
  const applyFontFamily =
    !className.includes('nofont-family') &&
    className === 'lint-customize-dropdown' &&
    type !== 'Font Weight' &&
    type !== 'Type';
  const title = getInteractiveTitle(selectedOption, type);

  return (
    <CustomDropDown
      value={selectedOption}
      aria-label={type}
      data-testid='sorting-toggle-button'
      onChange={(e: string) => setSelectedOption(e)}
      render={() => {
        return (
          <>
            {props.type === 'sorting' ? (
              <FilterIcon className='filterIcon' />
            ) : (
              <Tooltip info={title}>
                <div
                  className='lint-custom-ellipsis dropdown-text'
                  id='typeInteractive'
                  style={{
                    color: '#8F8F8F',
                    fontFamily: 'Lexend',
                    fontWeight: 350,
                    fontSize: '14px',
                    width: type === 'filter' ? '150px' : type === 'sorting' ? '100px' : '130px',
                  }}
                >
                  {title}
                </div>
              </Tooltip>
            )}
          </>
        );
      }}
      className='interactive-list-dropdown'
    >
      {Object.entries(optionCollection).map(([key, value]) => (
        <CustomDropDownOption
          key={`${key + '_' + value}`}
          data-testid='font-family-select-option'
          value={value}
          className={`${value === 'none' ? 'd-none' : ''} interactive-list-dropdown-option ${
            selectedOption === key ? 'selected' : ''
          }`}
        >
          <div title={key} className='interactive-dropdown-option-title'>
            <div
              className='text-capitalize lint-custom-ellipsis tooltip-container dropdown-text'
              data-toggle='tooltip'
              data-placement='right'
              style={{
                fontFamily: applyFontFamily ? (value as string) : 'Lexend',
                width: type === 'filter' ? '150px' : type === 'sorting' ? '100px' : '130px',
              }}
            >
              {key}
            </div>
          </div>
        </CustomDropDownOption>
      ))}
    </CustomDropDown>
  );
};

export default DropDownComponent;
