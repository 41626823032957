import React from 'react';
import { DropdownColorPicker } from '@crystaldelta/loree-ui-color-picker';
import { useLoreeTranslation } from '@crystaldelta/loree-util-i18n-react';
import { ReactComponent as BackgroundColorPicker } from '../editorFormattingIcons/backgroundColorButton.svg';
import { BackgroundColorButtonProps } from '../editorUtilityFunctions/lintEditorType';
import { BackGroundButton } from '../editorUtilityFunctions/styledContents';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { IconDiv } from '../editorUtilityFunctions/utilityFunctions';

export function BackgroundColorButton(props: BackgroundColorButtonProps) {
  const {
    currentColor,
    onChange,
    globalColorList,
    sharedColors,
    colorPickerFeatures,
    colorContrastRatio,
    colorPickerType,
  } = props;
  const { t } = useLoreeTranslation();

  return (
    <DropdownColorPicker
      onChange={onChange}
      element={
        <BackGroundButton aria-label={t('design.background')} data-testid='background-color-btn'>
          <Tooltip info='Background Color'>
            <IconDiv>
              <BackgroundColorPicker />
            </IconDiv>
          </Tooltip>
        </BackGroundButton>
      }
      currentColor={currentColor}
      globalColorList={globalColorList}
      sharedColors={sharedColors}
      colorPickerFeatures={colorPickerFeatures}
      colorContrastRatio={colorContrastRatio}
      colorPickerType={colorPickerType}
    />
  );
}
