import React from 'react';
import { Form } from 'react-bootstrap';
import DropDownComponent from '../utils/dropDown';

interface FontFamilyComponentInterface {
  setFontProperty: Function;
  fontPropertyValue: string;
  componentType: string;
  options: {};
  customFonts?: FontObject[];
}
interface FontObject {
  fontFamily: string;
  name: string;
  url?: string;
}

const FontHandlerComponent = (props: FontFamilyComponentInterface) => {
  const { setFontProperty, fontPropertyValue, componentType, options, customFonts } = props;
  let combinedFonts: { [key: string]: string } = {};

  if (componentType === 'Font Family') {
    if (customFonts) {
      for (let fonts of customFonts) {
        combinedFonts[fonts.name] = fonts.fontFamily;
      }
    }
    combinedFonts = { ...options, ...combinedFonts };
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    combinedFonts = Object.entries(combinedFonts)
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((r, [key, v]) => ({ ...r, [key]: v }), {});
  }
  const getFontFamilyValue = Object.keys(combinedFonts).find((key: string) => {
    return combinedFonts[key as keyof typeof combinedFonts] === fontPropertyValue;
  });

  const fontPropertyHandler = (value: string) => {
    let fontStyle: {} = {};

    switch (componentType) {
      case 'Font Family':
        fontStyle = {
          fontFamily: value,
        };
        break;
      case 'Font Size':
      case 'Size':
        fontStyle = {
          fontSize: value,
        };
        break;
      case 'Font Weight':
        fontStyle = {
          fontWeight: value,
        };
        break;
      case 'Type':
        fontStyle = {
          btnType: value,
        };
        break;
      case 'Separator size':
        fontStyle = {
          separatorWidth: value,
        };
        break;
      case 'No of cards in a row':
        fontStyle = {
          noOfCard: value,
        };
        break;
      case 'Number of layouts in a row':
        fontStyle = {
          noOfLayout: value,
        };
        break;
      case 'Icon Size':
        fontStyle = {
          iconSize: value,
        };
        break;
    }
    setFontProperty(fontStyle);
  };
  return (
    <Form.Group data-testid={`${componentType}-handler`}>
      <Form.Label className='design-section-sub-text'>{componentType}</Form.Label>
      <DropDownComponent
        setSelectedOption={fontPropertyHandler}
        selectedOption={
          componentType === 'Font Family' ? (getFontFamilyValue as string) : fontPropertyValue
        }
        className='lint-customize-dropdown'
        optionCollection={componentType === 'Font Family' ? combinedFonts : options}
        type={componentType}
      />
    </Form.Group>
  );
};

export default FontHandlerComponent;
