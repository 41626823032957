import React from 'react';
import { Form, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import { ReactComponent as LeftAlignment } from '../../assets/icons/left-aling-icon.svg';
import { ReactComponent as CenterAlignment } from '../../assets/icons/center-align-icon.svg';
import { ReactComponent as RightAlignment } from '../../assets/icons/right-align-icon.svg';

interface AlignmentComponentProps {
  alignmentValue: string;
  SetAlignmentValue: Function;
}

const AlignmentComponent = (props: AlignmentComponentProps) => {
  const { alignmentValue, SetAlignmentValue } = props;
  const setAlignmentHandler = (value: string) => {
    SetAlignmentValue({
      alignment: value,
    });
  };
  return (
    <Form.Group data-testid='alignment'>
      <Form.Label className='design-section-sub-text mb-3'>Alignment</Form.Label>
      <ButtonToolbar>
        <ButtonGroup className='alignment-button-group' size='sm'>
          <Button
            className={`alignment-buttons p-0 mr-2 ${alignmentValue === 'left' ? 'active' : ''}`}
            value='left'
            title='Left align'
            data-testid='Left align'
            onClick={() => setAlignmentHandler('left')}
          >
            <LeftAlignment />
          </Button>
          <Button
            className={`alignment-buttons p-0 mr-2 ${alignmentValue === 'center' ? 'active' : ''}`}
            value='center'
            title='Center align'
            data-testid='Center align'
            onClick={() => setAlignmentHandler('center')}
          >
            <CenterAlignment />
          </Button>
          <Button
            className={`alignment-buttons p-0 ${alignmentValue === 'right' ? 'active' : ''}`}
            value='right'
            title='Right align'
            data-testid='Right align'
            onClick={() => setAlignmentHandler('right')}
          >
            <RightAlignment />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Form.Group>
  );
};

export default AlignmentComponent;
