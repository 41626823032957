/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { getCustomizeComponent } from './utils';

const CustomizeComponent = (props: any) => {
  return (
    <div
      className='customize-container'
      style={{
        borderRight: '1px solid rgba(17, 34, 153, 0.20)',
      }}
    >
      <div className='fw-bolder title-text title-header mx-0 my-3 position-sticky'>Customize</div>
      {getCustomizeComponent(props, props.elementType)}
    </div>
  );
};

export default CustomizeComponent;
