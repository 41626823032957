import React from 'react';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { LintEditorFormatPropsType } from './lintEditorType';
import { EditorMenuButton, EditorMenuDropdown, EditorMenuDropdownButton } from './styledContents';
import {
  getFontName,
  getFontSize,
  getTextColor,
  isBulletList,
  isOrderedList,
} from '../editorExtensions/utilityFunctions';
import { ReactComponent as UnorderedListButton } from '../editorFormattingIcons/unorderedListButton.svg';
import { ReactComponent as DiscListButton } from '../editorFormattingIcons/discList.svg';
import { ReactComponent as CircleListButton } from '../editorFormattingIcons/circleList.svg';
import { ReactComponent as SquareListButton } from '../editorFormattingIcons/squareList.svg';
import { ReactComponent as OrderedListButton } from '../editorFormattingIcons/orderedListButton.svg';
import { ReactComponent as NumberListButton } from '../editorFormattingIcons/numberList.svg';
import { ReactComponent as AlphabetListButton } from '../editorFormattingIcons/alphabetList.svg';
import { ReactComponent as RomanListButton } from '../editorFormattingIcons/romanList.svg';
import { IconDiv } from './utilityFunctions';

export function ListElementOption(props: LintEditorFormatPropsType) {
  const { editor } = props;
  if (!editor) {
    return null;
  }

  const setBulletList = (numberingType: string) => {
    const size = getFontSize(editor)?.size;
    const metric = getFontSize(editor)?.unit;
    editor
      .chain()
      .focus()
      .toggleBulletListContent(numberingType)
      .updateNodeStyles('listItem', {
        color: getTextColor(editor),
        fontFamily: getFontName(editor),
        fontSize: `${size}${metric}`,
      })
      .run();
  };
  const setOrderedList = (numberingType: string) => {
    const size = getFontSize(editor)?.size;
    const metric = getFontSize(editor)?.unit;
    editor
      .chain()
      .focus()
      .toggleOrderedListContent(numberingType)
      .updateNodeStyles('listItem', {
        color: getTextColor(editor),
        fontFamily: getFontName(editor),
        fontSize: `${size}${metric}`,
      })
      .run();
  };

  return (
    <>
      <EditorMenuDropdown
        placement='bottom'
        element={
          <EditorMenuButton
            className={isBulletList(editor) ? 'is-active' : ''}
            data-testid='unordered-btn'
          >
            <Tooltip direction='bottom' info='Unordered list'>
              <IconDiv>
                <UnorderedListButton />
              </IconDiv>
            </Tooltip>
          </EditorMenuButton>
        }
      >
        <Tooltip info='Disc'>
          <EditorMenuDropdownButton
            onClick={() => setBulletList('disc')}
            data-testid='list-disc-btn'
          >
            <DiscListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
        <Tooltip info='Circle'>
          <EditorMenuDropdownButton
            onClick={() => setBulletList('circle')}
            data-testid='list-circle-btn'
          >
            <CircleListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
        <Tooltip info='Square'>
          <EditorMenuDropdownButton
            onClick={() => setBulletList('square')}
            data-testid='list-square-btn'
          >
            <SquareListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
      </EditorMenuDropdown>

      <EditorMenuDropdown
        placement='bottom'
        element={
          <EditorMenuButton
            className={isOrderedList(editor) ? 'is-active' : ''}
            data-testid='ordered-btn'
          >
            <Tooltip direction='bottom' info='Ordered list'>
              <IconDiv>
                <OrderedListButton />
              </IconDiv>
            </Tooltip>
          </EditorMenuButton>
        }
      >
        <Tooltip info='Numbers'>
          <EditorMenuDropdownButton
            onClick={() => setOrderedList('1')}
            data-testid='list-number-btn'
          >
            <NumberListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
        <Tooltip info='Alphabet'>
          <EditorMenuDropdownButton
            onClick={() => setOrderedList('a')}
            data-testid='list-alphabet-btn'
          >
            <AlphabetListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
        <Tooltip info='Roman'>
          <EditorMenuDropdownButton
            onClick={() => setOrderedList('i')}
            data-testid='list-roman-btn'
          >
            <RomanListButton />
          </EditorMenuDropdownButton>
        </Tooltip>
      </EditorMenuDropdown>
    </>
  );
}
