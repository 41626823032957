import Accordion from '../../assets/images/accordion.svg';
import Tabs from '../../assets/images/tab.svg';
import Button from '../../assets/images/button.svg';
import MCQ from '../../assets/images/multipleChoice.svg';
import ClickAndReveal from '../../assets/images/clickAndReveal.svg';
import Flipcard from '../../assets/images/flipCard.svg';
import ImageSlider from '../../assets/images/imageSlider.svg';
import DragAndDrop from '../../assets/images/dragAndDrop.svg';
import Hotspot from '../../assets/images/hotspot.svg';

export const libraryDetails = [
  {
    src: Accordion,
    alt: 'Accordion',
    title: 'Accordion',
    description:
      'Reduce the amount of text presented to users at a glance, whilst shortening pages and reducing scrolling.',
    pathName: '/component/Accordion',
    colorName: 'prgs-blue',
  },
  {
    src: Tabs,
    alt: 'Tabs',
    title: 'Tab',
    description:
      'Primary goal of tabs is to present users with a grouped view of content, saving valuable screen real estate.',
    pathName: '/component/Tabs',
    colorName: 'prgs-red',
  },
  {
    src: Button,
    alt: 'Button',
    title: 'Button',
    description: 'Allow users to take action/make a choice with a single tap.',
    pathName: '/component/Button',
    colorName: 'prgs-orange',
  },
  {
    src: MCQ,
    alt: 'Multiple Choice Question',
    title: 'multiplechoice',
    description: 'Allows users to easily build in a quick skill check.',
    pathName: '/component/multiplechoice',
    colorName: 'prgs-green',
  },
  {
    src: ClickAndReveal,
    alt: 'Click & Reveal Interactive',
    title: 'ClickAndRevealInteractions',
    description:
      'Allows users to offer additional information upon interaction. Great for a quick skill check.',
    pathName: '/component/ClickAndRevealInteractions',
    colorName: 'prgs-blue',
  },
  {
    src: Flipcard,
    alt: 'Flip Card',
    title: 'flipcard',
    description:
      'Take advantage of the movement/motion element within this interactive to increase learner engagement.',
    pathName: '/component/flipcard',
    colorName: 'prgs-red',
  },
  {
    src: ImageSlider,
    alt: 'Image/Video Slider',
    title: 'imageslider',
    description: 'Helps to pace delivery of visual information, whilst reducing scrolling.',
    pathName: '/component/imageslider',
    colorName: 'prgs-orange',
  },
  {
    src: DragAndDrop,
    alt: 'Drag and Drop',
    title: 'DragandDrop',
    description: 'Users can drag a particular data and drop it to specific object.',
    pathName: '/component/DragandDrop',
    colorName: 'prgs-green',
  },
  {
    src: Hotspot,
    alt: 'Image Hotspot',
    title: 'Hotspot',
    description:
      'Explore the ability to add hotspots that reveal text, images or videos to images that enrich the learning experience.',
    pathName: '/component/Hotspot',
    colorName: 'prgs-blue',
  },
];
