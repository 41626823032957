import React from 'react';
import { Form } from 'react-bootstrap';
import './customStyles.scss';
import { ReactComponent as LeftBorderIcon } from '../../assets/icons/left-border-icon.svg';
import { ReactComponent as RightBorderIcon } from '../../assets/icons/right-border-icon.svg';
import { ReactComponent as TopBorderIcon } from '../../assets/icons/top-border-icon.svg';
import { ReactComponent as BottomBorderIcon } from '../../assets/icons/bottom-border-icon.svg';

interface SpaceComponentInterface {
  componentType: string;
  left: string;
  top: string;
  right: string;
  bottom: string;
  SetLeft: Function;
  SetRight: Function;
  SetTop: Function;
  SetBottom: Function;
}

const SpaceComponent = (props: SpaceComponentInterface) => {
  const { componentType, left, top, right, bottom, SetLeft, SetRight, SetTop, SetBottom } = props;
  const isPadding = componentType === 'Padding';

  const handleCustomSpace = (event: React.ChangeEvent, type: string) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 40) return;
    switch (type) {
      case 'left':
        const leftValue = isPadding
          ? {
              customPaddingleft: value,
            }
          : {
              borderLeft: value,
            };
        SetLeft(leftValue);
        break;
      case 'top':
        const topValue = isPadding
          ? {
              customPaddingtop: value,
            }
          : {
              borderTop: value,
            };
        SetTop(topValue);
        break;
      case 'right':
        const rightValue = isPadding
          ? {
              customPaddingright: value,
            }
          : {
              borderRight: value,
            };
        SetRight(rightValue);
        break;
      case 'bottom':
        const bottomValue = isPadding
          ? {
              customPaddingbottom: value,
            }
          : {
              borderBottom: value,
            };

        SetBottom(bottomValue);
        break;
    }
  };
  return (
    <Form.Group className='border-input-group' data-testid={`${componentType}-component`}>
      <Form.Label className='design-section-sub-text m-0'>{`${componentType} (px)`}</Form.Label>
      <div className='d-flex mt-2 me-5 border-input-row'>
        <div className='border-input-container'>
          <LeftBorderIcon />
          <Form.Control
            data-testid={`${componentType}-left`}
            className='space-left-input enable-spinner'
            type='number'
            min='0'
            max='40'
            name='fontSize'
            size='sm'
            value={left}
            onChange={(e) => handleCustomSpace(e, 'left')}
          />
        </div>
        <div className='border-input-container'>
          <TopBorderIcon />
          <Form.Control
            className='space-top-input enable-spinner'
            data-testid={`${componentType}-top`}
            type='number'
            min='0'
            max='40'
            name='fontSize'
            size='sm'
            value={top}
            onChange={(e) => handleCustomSpace(e, 'top')}
          />
        </div>
      </div>
      <div className='d-flex mt-2 me-5 border-input-row'>
        <div className='border-input-container'>
          <RightBorderIcon />
          <Form.Control
            data-testid={`${componentType}-right`}
            className='space-right-input enable-spinner'
            type='number'
            min='0'
            max='40'
            name='fontSize'
            size='sm'
            value={right}
            onChange={(e) => handleCustomSpace(e, 'right')}
          />
        </div>
        <div className='border-input-container'>
          <BottomBorderIcon />
          <Form.Control
            data-testid={`${componentType}-bottom`}
            className='space-bottom-input enable-spinner'
            type='number'
            min='0'
            max='40'
            name='fontSize'
            size='sm'
            value={bottom}
            onChange={(e) => handleCustomSpace(e, 'bottom')}
          />
        </div>
      </div>
    </Form.Group>
  );
};

export default SpaceComponent;
