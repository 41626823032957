/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEventHandler, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';
import './modal.scss';

interface ModalComponentInterface {
  show: boolean;
  modalDetails: {
    title: string | JSX.Element;
    body: ReactNode;
  };
  backButton?: {
    disabled: boolean;
    label: string;
    handleBack: MouseEventHandler;
  };
  confirmButton?: {
    disabled: boolean;
    label: string;
    handleConfirm: MouseEventHandler;
  };
  cancelButton?: { label: string; handleCancel?: MouseEventHandler };
  handleCancel: MouseEventHandler;
  dialogClassName?: string;
  isCentered?: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  modalType?: string;
}

const ModalComponent = (props: ModalComponentInterface) => {
  const {
    show,
    size,
    modalDetails,
    backButton,
    confirmButton,
    cancelButton,
    handleCancel,
    dialogClassName,
    isCentered,
    modalType,
  } = props;
  const { title, body } = modalDetails;
  return (
    <Modal
      id='lint-custom-modal'
      size={size}
      show={show}
      aria-labelledby='confirmation-popup'
      centered={isCentered ?? true}
      className='lint-custom-modal'
      backdrop='static'
      dialogClassName={dialogClassName ? dialogClassName : ''}
    >
      <Modal.Header className='pb-2 justify-content-between '>
        <Modal.Title id='lint-custom-modal' className='d-flex' tabIndex={0}>
          {title}
        </Modal.Title>
        {modalType === 'preview' ? (
          <Button className='exit-preview-btn' onClick={handleCancel}>
            Exit Preview
          </Button>
        ) : (
          <div
            id='lint-custom-modal-close-icon'
            tabIndex={0}
            role='button'
            className='close-icon btn-close'
            onClick={handleCancel}
            onKeyDown={(event) => {
              if (event.key === ' ' || event.key === 'Enter') {
                event.preventDefault();
                handleCancel(event as any);
              }
            }}
          >
            <CloseIcon />
          </div>
        )}
      </Modal.Header>
      <Modal.Body className={modalType === 'preview' ? 'preview-modal-body' : ''} tabIndex={0}>
        {body}
      </Modal.Body>
      {(backButton ?? confirmButton ?? cancelButton) && (
        <Modal.Footer className='justify-content-center model-footer-element'>
          {backButton && (
            <button
              type='button'
              id={`lint-custom-modal-${backButton.label}-button`}
              className='btn btn-outline-primary editor-custom-cancel-button'
              disabled={backButton.disabled}
              onClick={backButton.handleBack}
            >
              {backButton.label}
            </button>
          )}
          {cancelButton && (
            <button
              type='button'
              id={`lint-custom-modal-${cancelButton.label}-button`}
              className='btn btn-outline-primary editor-custom-cancel-button'
              onClick={cancelButton.handleCancel ? cancelButton.handleCancel : handleCancel}
            >
              {cancelButton.label}
            </button>
          )}
          {confirmButton && (
            <button
              type='button'
              id={`lint-custom-modal-${confirmButton.label}-button`}
              className={`editor-btn-primary editor-custom-button btn btn-primary  ${
                confirmButton.label === 'Delete' ? 'delete-button' : ''
              }`}
              disabled={confirmButton.disabled}
              onClick={confirmButton.handleConfirm}
            >
              {confirmButton.label}
            </button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalComponent;
